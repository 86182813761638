import { useRef, useEffect } from "react";

// This code is taken from https://stackoverflow.com/questions/53024496/state-not-updating-when-using-react-state-hook-within-setinterval

export function useInterval(callback: () => void, delay: number | null): React.MutableRefObject<number | undefined>
{
    const intervalRef = useRef<number>();
    const callbackRef = useRef<() => void>(callback);

    // Remember the latest callback:
    useEffect(() =>
    {
        callbackRef.current = callback;
    }, [ callback ]);

    // Set up the interval:
    useEffect(() =>
    {
        if (typeof delay === 'number')
        {
            intervalRef.current = window.setInterval(() => callbackRef.current(), delay);

            // Clear interval if the component is unmounted or the delay changes:
            return () =>
            {
                if (intervalRef.current)
                {
                    window.clearInterval(intervalRef.current);
                }
            };
        }
    }, [ delay ]);

    // Returns a ref to the interval ID in case you want to clear it manually:
    return intervalRef;
}
