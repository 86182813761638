import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Alert, createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { getConfigurationValue, setConfigurationValue } from './components/data/local-storage/Configuration';
import { Services, setServiceURL } from './components/data/Services';
import { useForceUpdate } from './components/development/ForceUpdate';
import React from 'react';
import { availableThemes } from './components/shared/themes/ThemeManager';


const SERVICE_WORKER_CONFIG = {
  disable: true,
  force_remove_old: true
};

if ('serviceWorker' in navigator && !SERVICE_WORKER_CONFIG.disable) {
  // Register a service worker hosted at the root of the
  // site using the default scope.
  navigator.serviceWorker.register(`/service-worker.js`).then(
    registration => {
      console.log('Service worker registration succeeded:', registration);
    },
    error => {
      console.error(`Service worker registration failed: ${error}`);
    }
  );
} else {
  console.error('Service workers will not be used.');
}

if (SERVICE_WORKER_CONFIG.force_remove_old) {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then(registrations => {
      for (const registration of registrations) {
        registration.unregister();
      }
    });
  }
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

var isDeviceDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;

const targetTheme = availableThemes[getConfigurationValue<string>("theme", "dark")];

let __root_force_update = () => {
  document.write(`Fatal: __root_force_update() was called before it was defined.`);
};

export function ForceFullReRender() {
  __root_force_update();
}

function AppRoot() {
  const forceUpdate = useForceUpdate();
  __root_force_update = () => {
    forceUpdate();
  };
  return <ThemeProvider theme={targetTheme ?? availableThemes.solarizedDark}>
    <CssBaseline />
    <App />
  </ThemeProvider>;
}

root.render(
  <AppRoot />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
