import {
  AppBar,
  Backdrop,
  Box,
  Card,
  CardContent,
  CardHeader,
  LinearProgress,
  Skeleton,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";
import {
  getConfigurationValue,
  hasConfigurationValue,
  setConfigurationValue,
} from "../data/local-storage/Configuration";
import { serverConnection } from "../data/ServerConnection";
import "../nondisplay/FetchHook";

const initializers: {
  action: () => Promise<void> | void;
  config?: InitializerConfig;
}[] = [];
let _initialized = false;

export type InitializerConfig = {
  name: string;
  required: boolean;
};

export default function InitWrapper(props: { children: any }) {
  const [initialized, setInitialized] = React.useState(false);
  const [failure, setFailure] = React.useState<Error[]>([]);

  React.useEffect(() => {
    (async () => {
      _initialized = true;
      const promises: Promise<void>[] = [];
      for (const initializer of initializers) {
        promises.push(
          (async () => {
            try {
              await initializer.action();
            } catch (e) {
              if (initializer.config?.required) {
                setFailure((f) => [...f, e]);
              }
            }
          })(),
        );
      }
      await Promise.all(promises);
      setInitialized(true);
    })();
  }, []);

  if (!initialized) {
    return (
      <>
        <Box
          sx={{
            flexGrow: 1,
            width: "100%",
            margin: 0,
          }}
        >
          <AppBar position="static">
            <Toolbar>
              <Skeleton
                variant="circular"
                width={40}
                height={40}
                sx={{ margin: "0.25rem" }}
              />
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                <Skeleton
                  variant="text"
                  width={200}
                  height={40}
                  sx={{ margin: "0.5rem" }}
                />
              </Typography>
            </Toolbar>
          </AppBar>
          <Skeleton
            variant="rectangular"
            width="100%"
            height="100%"
            sx={{ margin: "0" }}
          />
        </Box>
      </>
    );
  }

  if (failure.length > 0) {
    return (
      <>
        <Card>
          <CardHeader title="Initialization failed" />
          <CardContent>
            <Typography>
              The following errors occurred during initialization:
            </Typography>
            <ul>
              {failure.map((e, i) => (
                <li key={i}>{e.message}</li>
              ))}
            </ul>
          </CardContent>
        </Card>
      </>
    );
  }

  return props.children;
}

export function registerInitializer(
  initializer: () => Promise<void> | void,
  config?: InitializerConfig,
) {
  if (_initialized) {
    throw new Error("Cannot register initializer after initialization");
  }
  initializers.push({
    action: initializer,
    config,
  });
}

registerInitializer(async () => {
  const configuration = await serverConnection.getClientConfigurationDefaults();
  for (const [key, value] of configuration) {
    if (!hasConfigurationValue(key)) {
      setConfigurationValue(key, value);
    }
  }
});

registerInitializer(async () => {
  const configuration =
    await serverConnection.getClientConfigurationFixedValues();
  for (const [key, value] of configuration) {
    setConfigurationValue(key, value);
  }
});
