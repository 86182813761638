import { Box, AppBar, Toolbar, Typography, CircularProgress } from "@mui/material";
import { Suspense } from "react";
import { Skeleton } from "@mui/material";
import { Backdrop } from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import CircularProgressWithContent from "../shared/CircularProgressWithContent";

export function DefaultPageSuspense(props: {
    children: any;
})
{
    return (
        <Suspense fallback={ <Box sx={ {
            flexGrow: 1,
            width: '100%',
            margin: 0,
        } }>
            <AppBar position="static">
                <Toolbar>
                    <Skeleton variant='circular' width={ 40 } height={ 40 } sx={ { margin: '0.25rem' } } />
                    <Typography variant="h6" component="div" sx={ { flexGrow: 1 } }>
                        <Skeleton variant='text' width={ 200 } height={ 40 } sx={ { margin: '0.5rem' } } />
                    </Typography>
                </Toolbar>
            </AppBar>
            <Backdrop open={ true }>
                <CircularProgressWithContent>
                    <DownloadIcon />
                </CircularProgressWithContent>
            </Backdrop>
        </Box> }>
            { props.children }
        </Suspense>
    );
}