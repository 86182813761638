/**
 * Common service names
 */
export enum Services {
  CoreDataService = "CoreDataServer",
  SensorDataService = CoreDataService,
  AuthService = CoreDataService,
  MapService = CoreDataService,
  PluginService = CoreDataService,
  OpenWeatherAPIService = "OpenWeatherAPI",
  GrafanaService = "Grafana",
}

export interface IServiceStatus {
  status: "ok" | "warning" | "failure";
  errors: string[];
}

export interface IService {
  serviceName?: string;
  onServiceRegistered?(name: string): void;
  onServiceUnregistered?(): void;
  getStatus?(): IServiceStatus;
}

export type ServiceIdentifier = Services | string;

const instances: Map<ServiceIdentifier, any> = new Map();

export function getService<T>(service: ServiceIdentifier): T {
  if (!instances.has(service)) {
    throw new Error(`Service ${service} not found`);
  }
  let instance = instances.get(service);
  if (instance === undefined) {
    throw new Error(`Service ${service} is undefined`);
  }
  return instance as T;
}

export function setService<T>(service: ServiceIdentifier, instance: T): void {
  removeService(service);

  instances.set(service, instance);

  let target: IService = instance as IService;

  if (target.onServiceRegistered) {
    target.onServiceRegistered(service);
  }
}

export function hasService(service: ServiceIdentifier): boolean {
  return instances.has(service);
}

export function getServiceStatus(
  service: ServiceIdentifier,
): IServiceStatus | null {
  let instance = instances.get(service);
  if (instance !== undefined) {
    let instanceService = instance as IService;
    if (instanceService.getStatus) {
      return instanceService.getStatus();
    }
  }
  return null;
}

export function removeService(service: ServiceIdentifier): boolean {
  let instance = instances.get(service);
  if (instance !== undefined) {
    let instanceService = instance as IService;
    if (instanceService.onServiceUnregistered) {
      instanceService.onServiceUnregistered();
    }
  }
  return instances.delete(service);
}

export function getOrCreateService<T>(
  service: ServiceIdentifier,
  factory: () => T,
): T {
  if (!instances.has(service)) {
    setService(service, factory());
  }
  return getService<T>(service);
}

const serviceURLMap = new Map<ServiceIdentifier, string>([
  [ Services.CoreDataService, `${window.location.protocol}//${window.location.hostname}:${window.location.port}/api` ],
  // [Services.CoreDataService, `http://dev.scarecro.laurelgrovewinefarm.com/api`],
  [
    Services.GrafanaService,
    `${window.location.protocol}//${window.location.hostname}:3000`,
  ],
]);

/**
 * Gets the URL for a service
 * @param service The service to get the URL for
 * @returns The URL for the service
 */
export function getServiceURL(service: ServiceIdentifier): string {
  let url = serviceURLMap.get(service);
  if (!url) {
    throw new Error(`Service ${service} not found`);
  }
  return url;
}

/**
 * Sets the URL for a service
 * @param service The service to set the URL for
 * @param url The URL for the service
 */
export function setServiceURL(service: ServiceIdentifier, url: string): void {
  serviceURLMap.set(service, url);
}
