import { LatLng, LatLngExpression } from "leaflet";
import delay from "../misc/Delay";
import { Services, getServiceURL, setService } from "./Services";
import { ApplyTransformers } from "./Transformers";
import { getConfigurationValue, setConfigurationValue } from "./local-storage/Configuration";
import { cache } from "./ResultCache";
import { time } from "console";
import { IGrowingArea } from "../../shared/IGrowingArea";
import * as VisMap from "../../shared/IVisualizationMapping";
import { ISensorIdentifier } from "../../shared/ISensorIdentifier";
import { json } from "stream/consumers";
import { IBoxPlotDataItem, IMapArea, IPlugin, ISensorField, ITilesetInfo, Notice, SapAnalysisParameter, SensorCollectionInformation, SensorInstanceInformation, SensorStatusBlob, ServerUsageStatistics, Tag, WindroseData } from "../../serverShared/SensorDefinitions";
import { GenerateVisualizationMappingRequest, LoginResponse } from "../../serverShared/SensorDefinitions";
import { Mutex } from "async-mutex";
import { TimeRange } from "./DateHelpers";
import { DatabaseAdministrationController } from "./microservices/DatabaseAdministration";

export default class ServerConnector {
  // The base URL for all API calls
  private serverHost: string;
  private clientDebugConfig: any = null;
  private clientAPIVersion: string = "1.0.0";
  private serverAPIVersion: string = "1.0.0";
  // The current authentication status
  private m_isAuthenticated: boolean = false;
  // Current user roles
  private m_roles: string[] = [];

  private sessionID: string;

  private urlMutexes: Map<string, Mutex> = new Map<string, Mutex>();

  private getMutexForUrl(url: string): Mutex {
    if (!this.urlMutexes.has(url)) {
      this.urlMutexes.set(url, new Mutex());
    }
    return this.urlMutexes.get(url)!;
  }

  public get baseURLTarget() {
    return this.serverHost;
  }

  constructor(serverHost: string) {
    // Generate a random session ID
    this.sessionID = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    setService<ServerConnector>(Services.CoreDataService, this);
    this.serverHost = serverHost;
    (async () => {
      const apiVersion = await this.getAPIVersion();
      if (apiVersion !== this.clientAPIVersion) {
        console.warn(`API version mismatch. Client: ${this.clientAPIVersion} Server: ${apiVersion}`);
      }
    })();
  }

  public get isAuthenticated(): boolean {
    return this.m_isAuthenticated;
  }

  public get roles(): string[] {
    return this.m_roles;
  }

  public set roles(value: string[]) {
    this.m_roles = value;
  }

  private async checkResponse(response: Response) {
    if (!response.ok) {
      let error = "Unknown error";
      try {
        error = await response.text();
      }
      catch (e) { }
      throw new Error(`[Server] ${error} (response code ${response.status})`);
    }
  }

  private async baseFetch(url: string, options?: RequestInit, config?: {
    retryCount?: number;
  }): Promise<Response> {
    let response: Response = null!;
    for (let i = 0; i < config?.retryCount || 30; i++) {
      try {
        response = await fetch(url, options);
        break;
      }
      catch (e) {
        await delay(1000);
        continue;
      }
    }
    if (response === null) {
      throw new Error("Failed to fetch");
    }
    return response;
  }

  /**
   * Performs a GET request to the server and returns the response as a string
   * @param url The URL to request
   * @returns The response as a string
   */
  private async baseGetString(url: string): Promise<string> {
    try {
      const response = await this.baseFetch(url);
      await this.checkResponse(response);
      return await response.text();
    }
    catch (e) {
      throw e;
    }
  }

  private async parseJSON(response: Response): Promise<any> {
    try {
      return await response.json();
    }
    catch (e) {
      throw new Error(`Failed to parse JSON response: ${e}`);
    }
  }

  /**
   * Performs a GET request to the server and returns the response as JSON
   * @param url The URL to request
   * @returns The response as an object, deserialized from JSON
   */
  private async baseGetJSON(url: string): Promise<any> {
    const request = await this.baseFetch(url);
    await this.checkResponse(request);
    return await this.parseJSON(request);
  }

  private async basePostJSON(url: string, data: any): Promise<any> {
    try {
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        let error = "Unknown error";
        try {
          error = await response.text();
        }
        catch (e) { }
        throw new Error(`[Server] ${error} (response code ${response.status})`);
      }
      return await response.json();
    }
    catch (e) {
      throw e;
    }
  }

  public getHostTarget(): string {
    return this.serverHost;
  }

  public async getAPIVersion(): Promise<string> {
    let url = `${this.serverHost}/status/version`;
    return await this.baseGetString(url);
  }

  public isClientAPIVersionCompatible(): boolean {
    return this.clientAPIVersion === this.serverAPIVersion;
  }

  public async getServiceIsAvailable(): Promise<boolean> {
    try {
      let url = `${this.serverHost}/status/available`;
      let response = await (await this.baseFetch(url, undefined, { retryCount: 1 })).text();
      return response === "true";
    }
    catch (e) {
      return false;
    }
  }

  public async getServiceFailures(): Promise<string[]> {
    let url = `${this.serverHost}/failures`;
    return await this.baseGetJSON(url);
  }


  @cache(240000)
  public async getSensorCollections(): Promise<string[]> {
    let url = `${this.serverHost}/sensor/sensorCollections`;
    return await this.baseGetJSON(url);
  }

  @cache(240000)
  public async getSensorCollectionsV2(): Promise<SensorCollectionInformation[]> {
    // Path: /v2/sensor/sensorCollections
    let url = `${this.serverHost}/v2/sensor/sensorCollectionsInformation`;
    const result = await this.baseGetJSON(url);
    console.log(result);
    return result;
  }

  @cache(240000)
  public async getUniqueSourcesFromSensorCollection(sensorCollection: string): Promise<string[]> {
    let url = `${this.serverHost}/sensor/${sensorCollection}/structure/uniqueSources`;
    return await this.baseGetJSON(url);
  }
  @cache(240000)
  public async getSensorFieldsFromSensorCollection(sensorCollection: string): Promise<ISensorField[]> {
    //http://localhost:5254/Sensor/{collection}/structure/fields
    let url = `${this.serverHost}/sensor/${sensorCollection}/structure/fields`;
    const json = await this.baseGetJSON(url);
    const fields: ISensorField[] = [];
    for (const key in json) {
      var fieldName: string = key;
      var field: ISensorField = json[key];
      field.field = fieldName;
      fields.push(field);
    }
    return fields;
  }

  public async getSensorData(sensorCollection: string, source: string, from: Date, to: Date, field?: string | undefined): Promise<{ x: Array<any>, y: Array<any>; }> {
    if (field === undefined) {
      throw new Error("Breaking API change: target field is now required.");
    }
    const result: { x: Array<any>, y: Array<any>; } = { x: [], y: [] };
    let baseURL = `${this.serverHost}/sensor/${sensorCollection}/${source}/data`;//from=${from.toISOString()}&to=${to.toISOString()}&field=${field}
    const chunkRanges = new TimeRange(from, to).toCacheFriendlyChunkSeries();
    console.log(`Loading sensor data from ${from.toISOString()} to ${to.toISOString()} in ${chunkRanges.length} chunks`, chunkRanges);
    const subrequests = chunkRanges.map(x => {
      return (async () => {
        const [start, end] = [x.start, x.end];
        let url = `${baseURL}?from=${start.toISOString()}&to=${end.toISOString()}&field=${field}`;
        let rawData: { x: Array<any>, y: Array<any>; } = await this.baseGetJSON(url);
        try {
          rawData.x = rawData.x.map((x: any) => new Date(x));
        }
        catch (e) {
          console.error(`Failed to parse dates for ${sensorCollection}/${source}/${field}`);
        }
        return rawData;
      })();
    });
    const chunkResults = await Promise.all(subrequests);
    chunkResults.map(x => { result.x.push(...x.x); result.y.push(...x.y); });
    return result;
  }
  @cache(60000)
  public async getLatestSensorReading(sensorCollection: string, source: string): Promise<any> {
    let url = `${this.serverHost}/sensor/${sensorCollection}/${source}/latest`;
    let rawData = await this.baseGetJSON(url);
    let transformedData = ApplyTransformers(rawData);
    return transformedData;
  }
  @cache(60000)
  public async getDateOfLatestSensorReading(sensorCollection: string, source: string): Promise<Date> {
    let url = `${this.serverHost}/sensor/${sensorCollection}/${source}/data/special/date-of-latest`;
    let rawData = await this.baseGetJSON(url);
    return new Date(rawData);
  }

  @cache(60000)
  public async getDateOfLastRainfall(sensorCollection: string, source: string): Promise<Date> {
    let url = `${this.serverHost}/sensor/${sensorCollection}/${source}/data/special/date-of-last-rainfall`;
    let rawData = await this.baseGetJSON(url);
    return new Date(rawData);
  }
  @cache(240000)
  public async getBoxPlotFormattedSensorData(sensorCollection: string, source: string, target_field: string, from: Date, to: Date, interval_seconds: number): Promise<IBoxPlotDataItem[]> {
    let url = `${this.serverHost}/sensor/${sensorCollection}/${source}/${target_field}/data/boxplot?from=${from.toISOString()}&to=${to.toISOString()}&interval=${interval_seconds}`;
    return await this.baseGetJSON(url);
  }
  @cache(240000)
  public async getSensorFieldUnits(sensorCollection: string, sensorFieldName: string): Promise<string> {
    let url = `${this.serverHost}/sensor/${sensorCollection}/structure/fields/${sensorFieldName}/units/`;
    return await this.baseGetString(url);
  }

  public async getClientDebugConfig(): Promise<any> {
    if (this.clientDebugConfig !== null && this.clientDebugConfig !== undefined) {
      return this.clientDebugConfig;
    }
    const url = `${this.serverHost}/client/debug/config`;
    const json = await this.baseGetJSON(url);
    this.clientDebugConfig = json;
    return json;
  }
  @cache(240000)
  public async getMapTileSets(): Promise<string[]> {
    const url = `${this.serverHost}/map/tilesets`;
    const json = await this.baseGetJSON(url);
    return json;
  }
  @cache(240000)
  public async getTileSetInfo(tileSetName: string): Promise<ITilesetInfo> {
    const url = `${this.serverHost}/map/tileset/${tileSetName}`;
    const json = await this.baseGetJSON(url);
    return json;
  }

  public async getSensorPosition(sensorCollection: string, sensorId: string): Promise<LatLng> {
    const instanceInfo = await this.getSensorInstanceInformation(sensorCollection, sensorId);
    return new LatLng(instanceInfo.latitude, instanceInfo.longitude);
  }

  @cache(2500)
  public async getSensorTags(sensorCollection: string, sensorId: string): Promise<Tag[]> {
    return (await this.getSensorInstanceInformation(sensorCollection, sensorId)).tags;
  }

  @cache(60000)
  public async getAllTags(): Promise<Tag[]> {
    // Path: /api/sensor/tags
    const url = `${this.serverHost}/sensor/tags`;
    const json = await this.baseGetJSON(url);
    return json;
  }

  @cache(240000)
  public async getSensorInstanceInformation(sensorCollection: string, sensorId: string): Promise<SensorInstanceInformation> {
    // Path: /api/sensor/{sensorCollection}/{sensorID}/info
    const url = `${this.serverHost}/sensor/${sensorCollection}/${sensorId}/info`;
    const json: SensorInstanceInformation = await this.baseGetJSON(url);
    json.sensor_collection = sensorCollection;
    json.getSensorCollectionInformation = async () => (await this.getSensorCollectionsV2()).find(x => x.data_source_name === sensorCollection);
    return json;
  }

  @cache(240000)
  public async getPlugins(): Promise<IPlugin[]> {
    await delay(1500);
    return [
      {
        id: 'python-scarecrow',
        name: "Python Scarecrow System",
        description: "Python Scarecrow System",
        version: "1.0.0",
        content: "https://scarecro.laurelgrovewinefarm.com",
        contentMode: 'url'
      },
      {
        id: 'unsafe-test',
        name: "Inline Test",
        description: "Inline testing plugin",
        version: "1.0.0",
        content: "<h1>Testing!</h1>",
        contentMode: 'unsafe-inline'
      }
    ];
  }
  @cache(240000)
  public async getMapAreas(): Promise<IMapArea[]> {
    const url = `${this.serverHost}/map/areas`;
    const json: IGrowingArea[] = await this.baseGetJSON(url);
    const result: IMapArea[] = json.map(
      (area: IGrowingArea) => {
        const latitudeIndex = 1;
        const longitudeIndex = 0;
        const mapArea: IMapArea = {
          id: area.id,
          display_name: area.display_name,
          points: area.coordinates.map((coord: number[]) => new LatLng(coord[latitudeIndex], coord[longitudeIndex])),
          numericalZoneIdentifier: area.numericalZoneIdentifier
        };
        return mapArea;
      }
    );

    return result;
  }
  @cache(60000)
  public async getVisualizationMappings(): Promise<VisMap.IVisualization[]> {
    const url = `${this.serverHost}/visualizations/all`;
    const json = await this.baseGetJSON(url);
    return json;
  }

  public async getServerUtilizationMetrics(): Promise<ServerUsageStatistics> {
    // Path: /api/serverLoad
    const url = `${this.serverHost}/serverLoad`;
    const json = await this.baseGetJSON(url);
    return json;
  }

  public async login(username: string, password: string): Promise<LoginResponse> {
    try {
      // Path: /api/auth/login
      const url = `${this.serverHost}/auth/login`;
      const json: LoginResponse = await this.basePostJSON(url, { username, password });
      this.m_isAuthenticated = json.success;
      return json;
    }
    catch (e) {
      return { success: false };
    }
  }

  @cache(10000) // Just enough time to allow multiple calls to be made in quick succession
  public async getUserScopes(): Promise<string[]> {
    // Path: /api/auth/scopes
    const url = `${this.serverHost}/auth/scopes`;
    const json = await this.baseGetJSON(url);
    return json;
  }

  public async refreshAuthStatus(): Promise<boolean> {
    // Path: /api/auth/isAuthenticated
    const url = `${this.serverHost}/auth/isAuthenticated`;
    const json: boolean = await this.baseGetJSON(url);
    this.m_isAuthenticated = json;
    return json;
  }

  public async getProfilePictureURL(username?: string): Promise<string | undefined> {
    let url = `${this.serverHost}/auth/profilePictureURL`;
    if (username !== undefined) {
      url += `?username=${username}`;
    }
    try {
      const json = await this.baseGetJSON(url);
      return json;
    }
    catch (e) {
      return undefined;
    }
  }


  public async getSapAnalysisParameters(): Promise<SapAnalysisParameter[]> {
    // Path: /api/sapAnalysis/parameters
    const url = `${this.serverHost}/sapAnalysis/parameters`;
    const json = await this.baseGetJSON(url);
    return json;
  }

  @cache(999999999)
  public async generateVisualizationPlan(body: GenerateVisualizationMappingRequest) {
    return await this.getMutexForUrl('/api/visualizations/generate').runExclusive(async () => {
      const url = `${this.serverHost}/visualizations/generate`;
      console.log(`Generating visualization with`, body);
      const json = await this.basePostJSON(url, body);
      return json;
    });
  }

  // Path: /api/clientConfiguration/defaults
  public async getClientConfigurationDefaults(): Promise<Map<string, any>> {
    const url = `${this.serverHost}/clientConfiguration/defaults`;
    const json = await this.baseGetJSON(url);
    // Convert to a map
    const result = new Map<string, any>();
    for (const key in json) {
      result.set(key, json[key]);
    }
    return result;
  }

  // Path: /api/clientConfiguration/fixed
  public async getClientConfigurationFixedValues(): Promise<Map<string, any>> {
    const url = `${this.serverHost}/clientConfiguration/fixed`;
    const json = await this.baseGetJSON(url);
    // Convert to a map
    const result = new Map<string, any>();
    for (const key in json) {
      result.set(key, json[key]);
    }
    return result;
  }

  // Path: /api/sensor/{sensorCollection}/{sensorID}/data/special/windrose-data
  @cache(999999999)
  public async getWindroseData(sensorCollection: string, sensorId: string, from: Date, to: Date): Promise<WindroseData[]> {
    const urlBase = `${this.serverHost}/sensor/${sensorCollection}/${sensorId}/data/special/windrose-data`;//?from=${from.toISOString()}&to=${to.toISOString()}
    const result: WindroseData[] = [];
    const requestChunks = new TimeRange(from, to).toCacheFriendlyChunkSeries();
    console.log(`Loading windrose data from ${from.toISOString()} to ${to.toISOString()} in ${requestChunks.length} chunks`, requestChunks);

    const subrequests = requestChunks.map(x => {
      return (async () => {
        const [start, end] = [x.start, x.end];
        const url = `${urlBase}?from=${start.toISOString()}&to=${end.toISOString()}`;
        const json = await this.baseGetJSON(url);
        for (const item of json) {
          item.time = new Date(item.time);
        }
        return json;
      })();
    });

    const subresults = await Promise.all(subrequests);

    for (const subresult of subresults) {
      result.push(...subresult);
    }

    return result;
  }

  // Path: /api/application/notices
  public async getApplicationNotices(): Promise<Notice[]> {
    const url = `${this.serverHost}/application/notices`;
    const json = await this.baseGetJSON(url) as Notice[];
    for (const notice of json) {
      for (const event of notice.events) {
        if (event.date !== undefined) {
          event.date = new Date(event.date);
        }
      }
    }
    return json;
  }


  @cache(60000)
  public async getSensorStatus(): Promise<SensorStatusBlob> {
    const url = `${this.serverHost}/sensor-status`;
    const json = await this.baseGetJSON(url) as SensorStatusBlob;
    console.log(`Got sensor status`, json);
    return json;
  }

  @cache(999999999999)
  public async getDatabaseAdministrationController(): Promise<DatabaseAdministrationController> {

    return new DatabaseAdministrationController(this.serverHost + '/databaseAdministration');
  }
}

export type PluginService = ServerConnector;
export type MapService = ServerConnector;
export type SensorDataService = ServerConnector;

const serverAddress = getServiceURL(Services.CoreDataService);
export const serverConnection = new ServerConnector(serverAddress);
