import { Box, BoxProps, Collapse, Grow } from "@mui/material";
import Header, { HeaderProps } from "./Header";
import React from "react";
export type BasicPageLayoutProps = {
    headerProps: HeaderProps;
    children: JSX.Element | JSX.Element[];
    overflow?: "hidden" | "auto" | "scroll" | "visible" | "initial" | "inherit" | undefined;
};

export type PersistentLeftMenuProps = {
    children: JSX.Element | JSX.Element[];
    closed?: boolean;
} & BoxProps;
const RawMenuOffset = 8;
function MenuOffset()
{
    return <Box sx={ {
        display: 'block',
        height: {
            xs: `${RawMenuOffset}vh`,
            sm: `${RawMenuOffset}vh`,
            md: `${RawMenuOffset - 2}vh`,
        }
    } } />;
}

export function LeftMenu(props: PersistentLeftMenuProps)
{
    if (props.closed) return null;
    return <Box sx={
        {
            minWidth: {
                xs: "100%",
                sm: "100%",
                md: "35%",
                lg: "30%",
                xl: "25%",
            },
            width: {
                xs: "100%",
                sm: "100%",
                md: "35%",
                lg: "30%",
                xl: "25%",
            },
            height: '100%',
            overflow: "auto",
            webkitScrollbar: {
                display: "none",
            },
            flexGrow: 1,
            marginRight: 1
        }
    } { ...props }>
        { props.children }
    </Box>;
}

export type PageBodyProps = {
    children: JSX.Element | JSX.Element[];
    scrollable?: boolean;
};

export function PageBody(props: PageBodyProps)
{
    return <Box sx={ {
        flexGrow: 50,
        marginTop: '0px',
        display: 'block',
        overflow: props.scrollable ? 'auto' : 'hidden',
    } }>
        { props.children }
    </Box>;
}

export default function PageRoot(props: BasicPageLayoutProps)
{
    return <RootStandardPageLayout>
        <Header { ...props.headerProps } />
        <Box sx={ {
            width: '100%',
            height: '100%',
            display: {
                xs: 'block',
                sm: 'block',
                md: 'flex',
            },
            flexDirection: {
                xs: 'column',
                sm: 'column',
                md: 'row',
            },
            overflow: {
                xs: 'auto',
                sm: 'auto',
                md: 'hidden',
            }
        } }>
            { props.children as any }
        </Box>
    </RootStandardPageLayout>;
}




export type StandardPageProps = {
    /**
     * The children of the page. The first child is the header, the rest are the body.
     */
    children: JSX.Element[];
};

/**
 * A standard page layout. The first child is the header, the rest are the body.
 */
export function RootStandardPageLayout(props: StandardPageProps)
{
    const [ header, ...body ] = props.children;

    return (
        <Box sx={ {
            display: 'flex',
            flexDirection: 'column',
            height: '100vh'
        } }>
            <Box sx={ {
                flex: '0 0 auto',
                padding: '0px'
            } }>
                { header }
            </Box>
            <Box sx={ {
                flex: 1,
                minHeight: '50px',
                overflow: 'auto',
            } }>
                { body }
            </Box>
        </Box>
    );
}