import { useEffect, useState } from "react";
import { serverConnection } from "../data/ServerConnection";
import { enqueueSnackbar } from "notistack";

export type UserScope = 'admin' | 'user' | 'guest' | 'dev' | 'databaseAdministration';

export type RequireUserScopeProps = {
    children: any;
    scopes: UserScope[];
    mode?: 'all' | 'any';
    unavailableComponent?: any;
    loadingComponent?: any;
};

export function RequireUserScope(props: RequireUserScopeProps)
{
    const [ isReady, setIsReady ] = useState(false);
    const [ canShow, setCanShow ] = useState(false);

    useEffect(() =>
    {
        (async () =>
        {
            try
            {
                const scopes = await serverConnection.getUserScopes();
                if (props.mode === 'all' || props.mode === undefined)
                {
                    setCanShow(props.scopes.every(scope => scopes.includes(scope)));
                }
                else
                {
                    setCanShow(props.scopes.some(scope => scopes.includes(scope)));
                }
                setIsReady(true);
            }
            catch (e: any)
            {
                enqueueSnackbar(e.message, { variant: 'error' });
            }
        })();
    }, [ props ]);

    if (!isReady)
    {
        return props.loadingComponent ?? null;
    }

    if (!canShow)
    {
        return props.unavailableComponent ?? null;
    }

    return props.children;
}