import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MobiledataOffIcon from '@mui/icons-material/MobiledataOff';
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Backdrop, Button, Card, CardContent, CardHeader, LinearProgress, Paper, Typography, Zoom } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { BrowserRouter, HashRouter, MemoryRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { serverConnection } from './components/data/ServerConnection';
import { getConfigurationValue, setConfigurationValue } from './components/data/local-storage/Configuration';

import { DefaultPageSuspense } from './components/misc/CodeSplitterHelpers';
import delay from './components/misc/Delay';
import InitWrapper from './components/misc/InitWrapper';
import PageNotFoundPage from './components/pages/PageNotFoundPage';
import { SensorStatusPage } from './components/pages/SensorStatusPage';
import { ServiceWorkerManager } from './components/pages/ServiceWorkerManager';
import BlockingOperationController from './components/shared/BlockingOperationController';
import FailureBoundary from './components/shared/FailureBoundary';
import PromptController from './components/shared/PromptController';
import LargeLogo from './media/logo_large.png';
import DatabaseAdministrationPage from './components/pages/DatabaseAdministration';
const HomePage = React.lazy(() => import('./components/pages/HomePage'));
const LoginPage = React.lazy(() => import('./components/pages/LoginPage'));
const LiveMap = React.lazy(() => import('./components/pages/MapViewer'));
const MapAreaDetailsPage = React.lazy(() => import('./components/pages/MapAreaDetailsPage'));
const PluginHost = React.lazy(() => import('./components/pages/PluginHost'));
const SAPAnalysisEntryForm = React.lazy(() => import('./components/pages/SAPAnalysisEntryForm'));
const SensorDataGraphing = React.lazy(() => import('./components/pages/SensorDataGraphing'));
const ServerLoadPage = React.lazy(() => import('./components/pages/ServerLoadPage'));
const ThreeDimensionalView = React.lazy(() => import('./components/pages/ThreeDimensionalView'));
const NetworkChecking = React.lazy(() => import('./components/development/NetworkChecking'));
const BuildInfoPage = React.lazy(() => import('./components/pages/BuildInfoPage'));
const DevConfiguration = React.lazy(() => import('./components/pages/DevConfiguration'));
const Testing = React.lazy(() => import('./components/shared/Testing'));
const UserSettingsPage = React.lazy(() => import('./components/pages/UserSettingsPage'));
const SeedCachePage = React.lazy(() => import('./components/pages/SeedCache'));
let loadingBannerShowFunction: (() => void) | undefined = undefined;



export function ShowLoadingBanner() {
  if (loadingBannerShowFunction) {
    loadingBannerShowFunction();
  }
}

function App() {
  const isDevelopment = process.env.NODE_ENV === 'development';
  const [isLoadingBannerVisible, setIsLoadingBannerVisible] = React.useState<boolean>(true);
  loadingBannerShowFunction = () => setIsLoadingBannerVisible(true);
  const [isLoggedIn, setIsLoggedIn] = React.useState<boolean>(false);
  const [connectionLost, setConnectionLost] = React.useState<boolean | undefined>(false);
  const [connectionLostGUI, setConnectionLostGUI] = React.useState<boolean>(false);

  const [routerTypeString] = React.useState<'browser' | 'hash' | 'memory'>(getConfigurationValue<'browser' | 'hash' | 'memory'>("dom-router", 'browser'));

  const [requireServerConnection] = React.useState<boolean>(() => {
    return getConfigurationValue<boolean>("require-server-connection", true);
  });

  const [showDevelopmentWarning, setShowDevelopmentWarning] = React.useState<boolean>(isDevelopment);

  useEffect(() => {
    setConfigurationValue("require-server-connection", requireServerConnection);
  }, [requireServerConnection]);


  useEffect(() => {
    if (!isLoadingBannerVisible) return;
    (async () => {
      await delay(1500);
      setIsLoadingBannerVisible(false);
    })();
  }, [isLoadingBannerVisible]);

  useEffect(() => {
    setConfigurationValue("dom-router", routerTypeString);
  }, [routerTypeString]);

  let CurrentRouterType = BrowserRouter;
  if (routerTypeString === 'hash') {
    CurrentRouterType = HashRouter;
  }
  else if (routerTypeString === 'memory') {
    CurrentRouterType = MemoryRouter;
  }
  else if (routerTypeString === 'browser') {
    CurrentRouterType = BrowserRouter;
  }

  useEffect(() => {
    if (connectionLost === true) {
      setConnectionLostGUI(true);
    }
    else {
      setConnectionLostGUI(false);
    }
  }, [connectionLost]);



  React.useEffect(() => {
    let isMounted = true;

    const connectivityCheckLazy = getConfigurationValue<number>("connectivity-check-lazy", 15000);
    const connectivityCheckActive = getConfigurationValue<number>("connectivity-check-active", 1000);

    const checkServerConnection = async () => {
      while (isMounted) {
        try {
          const isServerConnected = await serverConnection.getServiceIsAvailable();
          setConnectionLost(!isServerConnected);
        } catch (e) {
          setConnectionLost(true);
        }
        await delay(connectionLost ? connectivityCheckActive : connectivityCheckLazy);
      }
    };

    checkServerConnection();

    setTimeout(() => {
      setShowDevelopmentWarning(false);
    }, 10000);

    return () => {
      isMounted = false;
    };
  }, []);


  React.useEffect(() => {
    (async () => {
      let apiFailures = await serverConnection.getServiceFailures();
      if (apiFailures.length > 0) {
        enqueueSnackbar(`API server reported ${apiFailures.length} failures.`, { variant: 'warning' });
        enqueueSnackbar(`API service integrity compromised`, { variant: 'warning' });
        for (let failure of apiFailures) {
          enqueueSnackbar(`API failure: ${failure}`, { variant: 'warning' });
        }
      }
    })();
  }, []);

  return (
    <>
      <InitWrapper>
        <FailureBoundary failureHandler={(error, errorInfo, reset) => {
          return (
            <>
              <Backdrop open={true} sx={{ zIndex: 9999, }}>
                <Card sx={{
                  width: '50em'
                }}>
                  <CardHeader title="Application Error" />
                  <CardContent>
                    <Typography variant="body1">
                      An irrecoverable error has occurred, and the application cannot continue. Please reload the page. If the problem persists, please let the developers know.
                    </Typography>
                    <Button onClick={() => window.location.reload()}>Reload Current Page</Button>
                    <Button onClick={() => window.location.assign('/')}>Back to Home</Button>
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Technical Details</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          {error.toString()}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </CardContent>
                </Card>
              </Backdrop>
            </>
          );
        }}>
          <SnackbarProvider maxSnack={getConfigurationValue<number>("maximum-snackbar-items", 10)}>
            <PromptController>
              <BlockingOperationController>
                <Backdrop open={isLoadingBannerVisible && false} sx={{
                  zIndex: 9999,
                }}
                  transitionDuration={
                    isLoadingBannerVisible ? 0 : 750
                  }>
                  <Paper sx={{
                    width: '100%',
                    height: '100%',
                    // Center the logo
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                    <Avatar alt="Logo" src={LargeLogo} sx={{
                      width: '35%',
                      height: 'auto',
                    }} />
                  </Paper>
                </Backdrop>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DefaultPageSuspense>
                    <CurrentRouterType>
                      <Routes>
                        <Route path='/' element={<HomePage />} />
                        <Route path='/auth' element={<LoginPage />} />
                        <Route path='/map' element={<LiveMap />} />
                        <Route path='/map/details' element={<MapAreaDetailsPage />} />
                        <Route path='/sensorData' element={<SensorDataGraphing />} />
                        <Route path='/testing' element={<Testing />} />
                        <Route path='/database' element={<DatabaseAdministrationPage />} />
                        <Route path='/3D' element={<ThreeDimensionalView />} />
                        <Route path='/dev/cfg' element={<DevConfiguration />} />
                        <Route path='/dev/buildinfo' element={<BuildInfoPage />} />
                        <Route path='/plugin/:pluginID' element={<PluginHost />} />
                        <Route path='/dev/network' element={<NetworkChecking />} />
                        <Route path='/dev/serverload' element={<ServerLoadPage />} />
                        <Route path='/dev/cacheseed' element={<SeedCachePage />} />
                        <Route path='/sap/new' element={<SAPAnalysisEntryForm />} />
                        <Route path='/dev/serviceworkermanager' element={<ServiceWorkerManager />} />
                        <Route path='/sensorStatus' element={<SensorStatusPage />} />
                        <Route path='/user/settings' element={<UserSettingsPage />} />
                        <Route path='*' element={<PageNotFoundPage />} />
                      </Routes>
                    </CurrentRouterType>
                  </DefaultPageSuspense>
                  <Backdrop open={connectionLostGUI && requireServerConnection} sx={{
                    zIndex: 999999999999,
                    backdropFilter: "blur(50px)"
                  }}>
                    <Zoom in={connectionLostGUI}>
                      <Card sx={{ p: 2, textAlign: 'center' }}>
                        <Typography variant='h5'>Server Unavailable</Typography>
                        <MobiledataOffIcon sx={{ fontSize: 40 }} className="half-fade-half-second" />
                        <Typography variant='body1'>
                          Data server failed to respond. This page will automatically refresh when the server is available.
                        </Typography>
                        <Typography variant='body2'>
                          Connection Target: <code>{serverConnection.getHostTarget()}</code>
                        </Typography>
                        <br />
                        <LinearProgress variant='indeterminate' />
                      </Card>
                    </Zoom>
                  </Backdrop>
                </LocalizationProvider>
              </BlockingOperationController>
            </PromptController>
          </SnackbarProvider>
        </FailureBoundary>
      </InitWrapper>
    </>
  );
}

export default App;
