import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export type PageNotFoundProps = {};

export default function PageNotFoundPage(props: PageNotFoundProps) {
  const navigate = useNavigate();
  return (
    <>
      <Box sx={{
        // Horizontally and vertically center the content
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
      }}>
        <Box sx={{
          textAlign: 'center',
        }}>
          <Typography variant="h1">404</Typography>
          <Typography variant="h3">Page Not Found</Typography>
          <Button variant="contained" color="primary" onClick={() => {
            navigate('/');
          }}>Go Home</Button>
        </Box>
      </Box>
    </>
  );
}
