declare global
{
    interface Date
    {
        addDays(days: number): Date;
        startOfCurrentWeek(): Date;
    }
}

Date.prototype.addDays = function (days: number): Date
{
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
};

Date.prototype.startOfCurrentWeek = function (): Date
{
    var date = new Date(this.valueOf());
    var day = date.getDay();
    var diff = date.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return new Date(date.setDate(diff));
};

export class TimeRange
{
    private _start: Date;
    private _end: Date;

    constructor(start: Date, end: Date)
    {
        this._start = start;
        this._end = end;
    }

    public get start(): Date
    {
        return this._start;
    }

    public get end(): Date
    {
        return this._end;
    }

    public set start(value: Date)
    {
        this._start = value;
    }

    public set end(value: Date)
    {
        this._end = value;
    }

    public toCacheFriendlyChunkSeries(): TimeRange[]
    {
        {
            // If the distance between the start and end is less than 7 days, return a single chunk
            const days = (this._end.getTime() - this._start.getTime()) / (1000 * 3600 * 24);
            if (days < 7) 
            {
                return [ this ];
            }
        }

        let result: TimeRange[] = [];
        let currentStart = this._start;
        let currentEnd = this._start.addDays(7).startOfCurrentWeek();
        while (currentEnd < this._end)
        {
            result.push(new TimeRange(currentStart, currentEnd));
            currentStart = currentEnd;
            currentEnd = currentEnd.addDays(7).startOfCurrentWeek();
        }
        if (currentStart < this._end)
        {
            result.push(new TimeRange(currentStart, this._end));
        }
        return result;
    }
}