import { Alert, AlertTitle, Button, List, ListItem, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import PageRoot, { PageBody } from "../shared/BasicPageLayout";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { getConfigurationValue } from "../data/local-storage/Configuration";

export type ServiceWorkerManagerProps = {};

function ServiceWorkerStatus() {
  return (
    <Typography>
      {navigator.serviceWorker.controller ? "Active" : "Inactive"}
    </Typography>
  );
}

function ServiceWorkerActions() {
  const unregisterServiceWorker = async () => {
    try {
      const registration = await navigator.serviceWorker.getRegistration();
      if (registration) {
        registration.unregister();
        enqueueSnackbar("Service worker unregistered", { variant: 'success' });
      }
      else {
        enqueueSnackbar("Service worker not registered", { variant: 'error' });
      }
    }
    catch (e: any) {
      enqueueSnackbar(`Operation failed: ${e.message}`, { variant: 'error' });
    }
  };

  return (
    <Button color='error' onClick={unregisterServiceWorker}>
      Unregister Service Worker
    </Button>
  );
}

function ServiceWorkerListener() {
  const [messages, setMessages] = useState<string[]>([]);

  useEffect(() => {
    const eventListener = (event: MessageEvent) => {
      setMessages(messages => [...messages, event.data]);
    };

    navigator.serviceWorker.addEventListener('message', eventListener);

    return () => { navigator.serviceWorker.removeEventListener('message', eventListener); };
  }, []);

  return (
    <List>
      {messages.map((message, index) => (
        <ListItem key={index}>
          {JSON.stringify(message)}
        </ListItem>
      ))
      }
    </List>
  );
}

export function ServiceWorkerManager(props: ServiceWorkerManagerProps) {
  return <PageRoot headerProps={{
    title: "Service Worker Manager",
  }}>
    <PageBody>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              Key
            </TableCell>
            <TableCell>
              Value
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              Service Worker Status
            </TableCell>
            <TableCell>
              <ServiceWorkerStatus />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              Service Worker Registration
            </TableCell>
            <TableCell>
              <ServiceWorkerActions />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              Version
            </TableCell>
            <TableCell>
              {getConfigurationValue('current-service-worker-version', 'NONE')}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <ServiceWorkerListener />
    </PageBody>
  </PageRoot>;
};
