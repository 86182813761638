import { Box, CircularProgress, CircularProgressProps, Typography } from "@mui/material";
import React from "react";

type CircularProgressWithContentProps = {
    children: string | React.ReactNode;
} & CircularProgressProps;

export default function CircularProgressWithContent(props: CircularProgressWithContentProps)
{
    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress { ...props } />
            <Box
                top={ 0 }
                left={ 0 }
                bottom={ 0 }
                right={ 0 }
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                { props.children }
            </Box>
        </Box>
    );
}
