export function ISOUTCToLocalTime(date: string): string
{
    date += "Z"; // Add Zulu timezone
    // Convert to local timezone
    return new Date(date).toString();
}

/**
 * Apply transformers to an array or object
 * @param data An array or object to apply the transformers to
 * @returns The transformed array or object (reference, original object is modified)
 */
export function ApplyTransformers<T>(data: Array<T> | T): Array<T> | T
{
    if (Array.isArray(data))
    {
        return data.map((item) => ApplyTransformers(item)) as Array<T>;
    }
    else
    {
        let item = data as any;
        if (item.timestamp !== undefined)
        {
            item.timestamp = ISOUTCToLocalTime(item.timestamp);
        }
        if (item.date !== undefined)
        {
            item.date = ISOUTCToLocalTime(item.date);
        }
        if (item.time !== undefined)
        {
            item.time = ISOUTCToLocalTime(item.time);
        }
        return item;
    }
}