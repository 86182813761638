import { Box, Slide, Typography } from "@mui/material";
import React from "react";

export type GroupBoxProps = {
    title: string | JSX.Element;
    children: JSX.Element | JSX.Element[];
    disableTitleAnimation?: boolean;
};

export function GroupBox(props: GroupBoxProps)
{

    const TitleWrapperComponent = props.disableTitleAnimation ? React.Fragment : Slide;
    const TitleWrapperProps = props.disableTitleAnimation ? {} : { in: true };

    return <Box component='fieldset' sx={ {
        borderRadius: theme => theme.spacing(1),
    } }>
        <legend>
            {
                typeof props.title === 'string' ? <TitleWrapperComponent { ...TitleWrapperProps }>
                    <Typography>
                        { props.title }
                    </Typography>
                </TitleWrapperComponent> : props.title
            }
        </legend>
        { props.children }
    </Box>;
}