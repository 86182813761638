import { Alert, AlertTitle, Typography } from "@mui/material";
import React from "react";

function DefaultFailureDisplay(props: { componentName?: string; })
{
    return (
        <>
            <Alert severity="error">
                <AlertTitle>Critical Component Failure
                    {
                        props.componentName !== undefined && <> in component '{ props.componentName }'</>
                    }</AlertTitle>
                <Typography variant="body1">
                    This component encountered an unhandled error and could not recover.
                </Typography>
            </Alert>
        </>
    );
}

export interface FailureBoundaryProps
{
    /**
     * The fallback UI to display when an error occurs. 
     * If this is not provided, a default fallback UI will be displayed.
     * Read details for behavior between this and the failureHandler prop, otherwise your're gonna have a bad time.
     */
    fallback?: JSX.Element;
    componentName?: string;
    /**
     * A function that handles errors. If this is provided, the fallback prop will be ignored. 
     * If it is not provided, the fallback prop will be used. 
     * If the function returns an object, that object will be rendered instead of the fallback prop.
     * If the function returns undefined, null, or void, the fallback prop will be used.
     */
    failureHandler?: (error: Error, errorInfo: React.ErrorInfo, reset: () => void) => (React.ReactNode | undefined | null | void);
    /**
     * The children to render.
     */
    children: React.ReactNode;
}

/**
 * A component that catches errors in its children and displays a fallback UI.
 */
export default class FailureBoundary extends React.Component<FailureBoundaryProps, { hasError: boolean; error: any, errorInfo: any; }>
{
    constructor(props)
    {
        super(props);
        this.state = { hasError: false, error: null, errorInfo: null };
    }

    static getDerivedStateFromError(error)
    {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error: error };
    }

    componentDidCatch(error: any, errorInfo: any)
    {
        this.setState({ hasError: true, error: error, errorInfo: errorInfo });
    }

    render()
    {
        if (this.state.hasError)
        {
            if (this.props.failureHandler !== undefined)
            {
                const result = this.props.failureHandler(this.state.error, this.state.errorInfo, () => this.setState({ hasError: false, error: null, errorInfo: null }));
                if (result instanceof Object)
                {
                    return result;
                }
            }
            return this.props.fallback ?? <DefaultFailureDisplay componentName={ this.props.componentName } />;
        }
        return this.props.children;
    }
}