import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React from "react";

/**
 * Configuration for a prompt action button.
 */
export interface IPromptButton
{
    label: string;
    onClick: () => void;
}
/**
 * Prompt configuration.
 */
export interface IPrompt
{
    title: string;
    body: React.ReactNode;
    actions: IPromptButton[];
}

// TODO: Use a stack of prompts to allow for multiple prompts to be open at once.
var currentPrompt: IPrompt | null = null;
var closePromptController = () => { };
var openPromptController = (prompt: IPrompt) => { };

export interface PromptControllerProps
{
    children: React.ReactNode[] | React.ReactNode;
}

export default function PromptController(props: PromptControllerProps)
{
    const [ isOpen, setIsOpen ] = React.useState(false);

    closePromptController = () =>
    {
        currentPrompt = null;
        setIsOpen(false);
    };

    openPromptController = (prompt: IPrompt) =>
    {
        currentPrompt = prompt;
        setIsOpen(true);
    };

    return (
        <>
            <Dialog open={ isOpen } onClose={ () => { } }>
                <DialogTitle>{ currentPrompt?.title }</DialogTitle>
                <DialogContent>
                    { currentPrompt?.body }
                </DialogContent>
                <DialogActions>
                    {
                        currentPrompt?.actions.map((button, index) =>
                        {
                            return (
                                <Button key={ index } onClick={ button.onClick }>{ button.label }</Button>
                            );
                        })
                    }
                </DialogActions>
            </Dialog>
            {
                props.children
            }
        </>
    );

}

export function usePromptController()
{
    return {
        closePrompt: closePromptController,
        openPrompt: openPromptController
    };
}