
function getKey(key: string): string
{
    return `config-${key}`;
}

/**
 * Gets a configuration value
 * @param key The key of the value to get
 * @param defaultValue The default value to use if the config value does not exist
 * @returns The value of the cookie, or the default value if the config value does not exist 
 */
export function getConfigurationValue<T>(key: string, defaultValue: T): T
{
    const value = localStorage.getItem(getKey(key));
    if (value === null)
    {
        setConfigurationValue(key, defaultValue);
        return defaultValue;
    }
    return JSON.parse(value) as T;
}

/**
 * Gets a configuration value
 * @param key The key of the value to get
 * @returns The value of the cookie, or throws an error if the config value does not exist
 */
export function getRequiredConfigurationValue<T>(key: string): T
{
    const value = localStorage.getItem(getKey(key));
    if (value === null)
    {
        throw new Error(`Required configuration value ${key} does not exist`);
    }
    return JSON.parse(value) as T;
}

/**
 * Sets a configuration value
 * @param key The key of the value to set
 * @param value The desired value
 * @returns Nothing
 */
export function setConfigurationValue<T>(key: string, value: T): void
{
    localStorage.setItem(getKey(key), JSON.stringify(value));
}

/**
 * Remove a configuration value
 * @param key The name of the value to remove
 */
export function removeConfigurationValue(key: string): void
{
    localStorage.removeItem(getKey(key));
}

/**
 * Get all keys
 * @returns All config keys
 */
export function getAllConfigurationValueKeys(): string[]
{
    return Object.keys(localStorage).filter(key => key.startsWith("config-")).map(key => key.substring(7));
}

/**
 * Determines if a configuration value exists
 * @param key The key of the value to check
 * @returns True if the value exists, false otherwise
 */
export function hasConfigurationValue(key: string): boolean
{
    return localStorage.getItem(getKey(key)) !== null;
}