import { SensorCollectionInformation, SensorInstanceInformation } from "../../../serverShared/SensorDefinitions";
import { serverConnection } from "../ServerConnection";

export class DatabaseAdministrationController
{
    private url: string;

    constructor(url: string)
    {
        this.url = url;
    }

    public async updateSensorMetadata(sensorCollection: string, sensorID: string, targetField: string, newValue: any)
    {
        //http://laptop.nic.walterneils.com/api/databaseAdministration/sensor/weather_rack/WS3/updateInstanceMetadata
        const url = `${this.url}/sensor/${sensorCollection}/${sensorID}/updateInstanceMetadata`;
        const body = {
            field: targetField,
            value: newValue
        };
        const response = await fetch(url, { method: 'POST', body: JSON.stringify(body) });
        if (response.status !== 200)
        {
            throw new Error(`Failed to update sensor metadata: ${response.status} ${response.statusText}`);
        }

        return;
    }


    public async insertSensor(sensorInformation: SensorInstanceInformation)
    {
        const sensorCollection = sensorInformation.sensor_collection;
        // `${PATH_ROOT}/sensor/:sensorCollection/insertInstance
        const url = `${this.url}/sensor/${sensorCollection}/insertInstance`;
        const response = await fetch(url, { method: 'POST', body: JSON.stringify(sensorInformation) });
        if (response.status !== 200)
        {
            throw new Error(`Failed to insert sensor: ${response.status} ${response.statusText}`);
        }
    }

    public async deleteSensor(sensorInformation: SensorInstanceInformation)
    {
        throw new Error(`Failed to delete sensor ${sensorInformation.sensor_collection}/${sensorInformation.instance_unique_identifier}: NOT_IMPLEMENTED`);
    }

    public async upsertSensor(sensorInformation: SensorInstanceInformation)
    {
        const sensorCollection = sensorInformation.sensor_collection;
        // `${PATH_ROOT}/sensor/:sensorCollection/upsertInstance
        const url = `${this.url}/sensor/${sensorCollection}/upsertInstance`;
        const response = await fetch(url, { method: 'POST', body: JSON.stringify(sensorInformation) });
        if (response.status !== 200)
        {
            throw new Error(`Failed to upsert sensor: ${response.status} ${response.statusText}`);
        }
        try
        {
            (serverConnection.getSensorInstanceInformation as any).clearCache();
        }
        catch (e)
        {
            console.log(`Failed to clear cache: ${e}`, e);
        }
    }

    public async upsertDataSource(dataSourceInformation: SensorCollectionInformation)
    {
        const url = `${this.url}/datasource/upsert`;
        const response = await fetch(url, { method: 'POST', body: JSON.stringify(dataSourceInformation) });
        if (response.status !== 200)
        {
            throw new Error(`Failed to upsert data source: ${response.status} ${response.statusText}`);
        }

        try
        {
            (serverConnection.getSensorCollectionsV2 as any).clearCache();
        }
        catch (e)
        {
            console.log(`Failed to clear cache: ${e}`, e);
        }

        try
        {
            (serverConnection.getSensorCollections as any).clearCache();
        }
        catch (e)
        {
            console.log(`Failed to clear cache: ${e}`, e);
        }
    }

    public async flushCache()
    {
        // /api/databaseAdministration/flushCache
        const url = `${this.url}/flushCache`;
        const response = await fetch(url);
        if (response.status !== 200)
        {
            throw new Error(`Failed to flush cache: ${response.status} ${response.statusText}`);
        }
    }
}