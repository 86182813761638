import { getConfigurationValue } from "./local-storage/Configuration";

const CACHE_ENABLED = getConfigurationValue<boolean>('caching-enabled', true);

export function cache(duration: any) {
  if (!CACHE_ENABLED) {
    return function(target: any, propertyKey: string, descriptor: PropertyDescriptor) {
      return descriptor;
    };
  }
  else {
    return function(target: any, propertyKey: string, descriptor: PropertyDescriptor) {
      const originalMethod = descriptor.value;
      const cache = new Map();
      descriptor.value = function(...args: any[]) {
        const key = JSON.stringify(args);
        if (cache.has(key)) {
          const cached = cache.get(key);
          if (cached.timeStamp + duration > Date.now()) {
            return cached.value;
          }
          else {
            cache.delete(key);
          }
        }
        const result = originalMethod.apply(this, args);
        cache.set(key, {
          value: result,
          timeStamp: Date.now()
        });
        return result;
      };
      descriptor.value.clearCache = () => cache.clear();
      return descriptor;
    };
  }
}

export type LocalStorageCachedResultTypeMapEntry = {
  type: 'number' | 'string' | 'boolean' | 'object' | 'array' | 'date';
  path?: string;
  canBeNullOrUndefined: boolean;
};

type LocalStorageCacheConfigurationBase = {
  /**
   * The behavior of the cache. 
   */
  mode: 'fast' | 'accurate';
  /**
   * An array of objects that describe the types of the properties of the result of the function. This is used to rehydrate the result of the function from the cache.
   */
  rehydrationMap?: LocalStorageCachedResultTypeMapEntry[];
};

export type LocalStorageCacheConfiguration = LocalStorageCacheConfigurationBase & ({
  mode: 'fast';
  /**
   * The interval, in milliseconds, at which the cached result should be refreshed. If the cached result is older than this value, it will still be returned, but 
   * the underlying function will be invoked in the background to refresh the cached result.
   */
  refreshInterval: number;
  /**
   * The maximum age, in milliseconds, that the cached result should be considered valid for. If the cached result is older than this value, it will be refreshed, and
   * the updated result will be returned when it becomes available.
   */
  maximumAcceptableResultAge: number;
} | {
  mode: 'accurate';
  /**
   * The maximum age, in milliseconds, that the cached result should be considered valid for. If the cached result is older than this value, it is considered invalid, 
   * and the underlying function will be invoked to refresh the cached result.
   */
  duration: number;
});

interface LocalStorageCacheEnabledFunction<TRes, TArgs extends any[]> {
  (...args: TArgs): TRes;
  clearCachedResult(...args: TArgs): void;
  updateCachedResult(...args: TArgs): Promise<TRes>;
  underlyingFunction: (...args: TArgs) => Promise<TRes>;
  subscribeToBackgroundRefreshCompletionEvents(callback: (args: TArgs, result: TRes) => void): number;
  unsubscribeFromBackgroundRefreshCompletionEvents(subscriptionID: number): void;
}

/**
 * Exposes the cache controls for a function that has been decorated with the localStorageCacheAsync decorator.
 * @param targetMethod The function that has been decorated with the localStorageCacheAsync decorator.
 * @returns The cache controls for the function, or null if the function has not been decorated with the localStorageCacheAsync decorator.
 */
export function getLocalStorageCacheControls<TRes, TArgs extends any[]>(targetMethod: any) {
  if ('clearCachedResult' in targetMethod && 'updateCachedResult' in targetMethod) {
    return targetMethod as LocalStorageCacheEnabledFunction<TRes, TArgs>;
  }
  else {
    throw new Error('The function provided is not a function that has been decorated with the localStorageCacheAsync decorator.');
  }
}

export type CachedMethodCallOperationResultType = 'full-fetch' | 'full-cached' | 'cached-background-refresh';

export function localStorageCacheAsync(configuration: LocalStorageCacheConfiguration) {
  if (!CACHE_ENABLED) {
    return function(target: any, propertyKey: string, descriptor: PropertyDescriptor) {
      return descriptor;
    };
  }
  else {
    let decoratorResult: any = function(target: any, propertyKey: string, descriptor: PropertyDescriptor) {
      const originalMethod = descriptor.value;
      const functionKey = `${target.constructor.name}.${propertyKey}`;

      const getKey = (...args: any[]) => `${functionKey}-${JSON.stringify(args)}`;

      const refreshCache = async (on: any, ...args: any[]) => {
        const result = await originalMethod.bind(on)(...args);
        localStorage.setItem(getKey(args), JSON.stringify({
          value: result,
          timeStamp: Date.now()
        }));
        return result;
      };

      const subscriptions = new Map<number, (args: any[], result: any) => void>();

      const resultFinalizer = (result: any, resultType: CachedMethodCallOperationResultType) => {
        if (result === undefined || result === null) {
          return result;
        }
        if (configuration.rehydrationMap) {
          for (const step of configuration.rehydrationMap) {
            const target = step.path ? result[step.path] : result;
            if ((target === null || target === undefined) && !step.canBeNullOrUndefined) {
              throw new Error(`The result of the function ${functionKey} was null or undefined, but the type safety manager was expecting a ${step.type}.`);
            }

            if (step.type === 'date') {
              if (typeof target === 'string') {
                // Parse the date from the string.
                const parsed = new Date(target);
                if (isNaN(parsed.getTime())) {
                  throw new Error(`The result of the function ${functionKey} was a string that could not be parsed into a date.`);
                }
                else {
                  if (step.path) {
                    result[step.path] = parsed;
                  }
                  else {
                    result = parsed;
                  }
                }
              }
              else if (!(target instanceof Date)) {
                throw new Error(`The result of the function ${functionKey} was not a date.`);
              }
            }
          }
        }

        try {
          result.__CACHE_RESULT_TYPE__ = resultType;
        }
        catch (e: any) {
          // Ignore.
        }

        return result;
      };

      descriptor.value = async function(...args: any[]) {
        const key = JSON.stringify(args);
        const cachedItem = localStorage.getItem(getKey(args)) as string | null;
        if (cachedItem) {
          const cached: {
            value: any;
            timeStamp: number;
          } = JSON.parse(cachedItem);
          if (configuration.mode === 'accurate') {
            if (cached.timeStamp + configuration.duration > Date.now()) {
              return resultFinalizer(cached.value, 'full-cached');
            }
            else {
              return resultFinalizer(await refreshCache(this, ...args), 'full-fetch');
            }
          }
          else if (configuration.mode === 'fast') {
            if (cached.timeStamp + configuration.refreshInterval > Date.now()) {
              // If the cached result is still valid, return it.
              return resultFinalizer(cached.value, 'full-cached');
            }
            else if (cached.timeStamp + configuration.maximumAcceptableResultAge > Date.now()) {
              refreshCache(this, ...args).then(result => {
                for (const subscription of subscriptions.values()) {
                  try {
                    subscription(args, result);
                  }
                  catch (e: any) {
                    console.error(e);
                  }
                }
              });
              return resultFinalizer(cached.value, 'cached-background-refresh');
            }
            else {
              // The cached result is too old, so we need to refresh it.
              // See below.
            }
          }
        }
        const result = resultFinalizer(await refreshCache(this, ...args), 'full-fetch');
        return result;
      };

      const extended = descriptor.value as LocalStorageCacheEnabledFunction<any, any[]>;
      let currentID = 0;

      extended.subscribeToBackgroundRefreshCompletionEvents = (callback: (args: any[], result: any) => void) => {
        subscriptions.set(currentID, callback);
        return currentID++;
      };
      extended.unsubscribeFromBackgroundRefreshCompletionEvents = (subscriptionID: number) => subscriptions.delete(subscriptionID);

      extended.clearCachedResult = (...args: any[]) => localStorage.removeItem(`${target.constructor.name}.${propertyKey}-${JSON.stringify(args)}`);
      extended.updateCachedResult = async (...args: any[]) => refreshCache(this, ...args);
      extended.underlyingFunction = originalMethod;

      return descriptor;
    };

    return decoratorResult;
  }
}
