import { ReactElement } from "react";
import { AsArray } from "../nondisplay/AsArray";

export type DenullifierProps<T> = { children: T[] | T };

export function Denullifier<T extends ReactElement>(
  props: DenullifierProps<T>,
) {
  return <>{AsArray(props.children).filter((child) => child !== null)}</>;
}
