import { ReactElement, Suspense, useState } from "react";
import { SensorInstanceInformation, SensorStatusBlob, Tag, WeatherRackStatus } from "../../serverShared/SensorDefinitions";
import PageRoot, { PageBody } from "../shared/BasicPageLayout";
import { serverConnection } from "../data/ServerConnection";
import { enqueueSnackbar } from "notistack";
import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Alert, AlertTitle, Avatar, Backdrop, Box, Button, Card, CardProps, Chip, CircularProgress, Collapse, IconButton, List, ListItemAvatar, ListItemButton, ListItemText, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography, styled } from "@mui/material";
import { HumanizeDurationLanguage, HumanizeDuration, HumanizeDurationOptions } from "humanize-duration-ts";
import { Masonry } from "@mui/lab";
import FailureBoundary from "../shared/FailureBoundary";
import { TransitionGroup } from "react-transition-group";
import ImageIcon from '@mui/icons-material/Image';
import { URLMappedValueIntentBuilder, useURLMappedStateValue } from "../misc/URLMappedStateValue";
import { AutoGraph, Battery0Bar, Battery1Bar, BatteryFull, Check, Launch, Map as MapIcon, PriorityHigh, QuestionMark } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Denullifier } from "../misc/Denullifier";
import { useInterval } from "../nondisplay/UseInterval";

const langService: HumanizeDurationLanguage = new HumanizeDurationLanguage();
const humanizer: HumanizeDuration = new HumanizeDuration(langService);

export type ModernCardProps = {

} & CardProps;

const StyledCard = styled(Card)(({ theme }) => ({
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: theme.palette.text.disabled,
  padding: theme.spacing(2),
  borderRadius: '4px',
  ":hover": {
    boxShadow: theme.shadows[4],
  },
  transitionDuration: '0.2s',
  transitionProperty: 'box-shadow',
}));

export function ModernCard(props: ModernCardProps) {
  return (
    <StyledCard {...props}>
      {props.children}
    </StyledCard>
  );
}

export type SensorStatusIndicatorProviderProps = {
  sensorCollection: string,
  sensorID: string,
};

export type SensorStatusIndicatorProvider = (props: SensorStatusIndicatorProviderProps) => JSX.Element;

const defaultStatusProviderMap: Map<string, SensorStatusIndicatorProvider[]> = new Map();

const registerProvider = (sensorType: string, provider: SensorStatusIndicatorProvider) => {
  if (!defaultStatusProviderMap.has(sensorType)) {
    defaultStatusProviderMap.set(sensorType, []);
  }

  defaultStatusProviderMap.get(sensorType)!.push(provider);
};

const getProviders = (sensorType: string): SensorStatusIndicatorProvider[] => {
  // Get the default providers.
  const anyProviders = defaultStatusProviderMap.get('any') ?? [];
  const sensorTypeProviders = defaultStatusProviderMap.get(sensorType) ?? [];

  return [...anyProviders, ...sensorTypeProviders];
};

function GenericReadingStatusProvider(props: SensorStatusIndicatorProviderProps) {
  if (props.sensorCollection === undefined) {
    throw new Error(`Sensor collection is undefined`);
  }

  const considerSensorOfflineAfter = 1000 * 60 * 60; // 1 hour
  const [lastReading, setLastReading] = useState<Date>();
  const [timeSinceLastReading, setTimeSinceLastReading] = useState<string | null>(null);

  const isSensorOffline = () => {
    if (lastReading === undefined) {
      return false;
    }

    return new Date().getTime() - lastReading.getTime() > considerSensorOfflineAfter;
  };

  const updateTimeSinceReading = () => {
    if (lastReading === undefined) {
      return;
    }
    const timeSinceLastReading = (Date.now() - lastReading.getTime());

    const humanizerOptions: HumanizeDurationOptions = {
      units: ['d', 'h', 'm'],
      round: true,
      largest: 2
    };

    let newValue: string = null!;

    // Make sure the reading isn't from the future.
    if (timeSinceLastReading < 0) {
      newValue = `In ${humanizer.humanize(-timeSinceLastReading, humanizerOptions)}`;
    }
    else {
      newValue = `${humanizer.humanize(timeSinceLastReading,
        humanizerOptions)} ago`;
    }

    if (newValue == '0 minutes ago') {
      newValue = 'Just now';
    }

    setTimeSinceLastReading(newValue);
  };

  const refreshSensorStatus = async () => {
    try {
      const lastReading = await serverConnection.getDateOfLatestSensorReading(props.sensorCollection, props.sensorID);
      setLastReading(lastReading);
    }
    catch (e: any) {
      enqueueSnackbar(`Failed to get sensor status: ${e.message}`, { variant: "error" });
      console.error(e);
    }
  };

  React.useEffect(() => {
    refreshSensorStatus();
  }, []);

  useInterval(() => {
    refreshSensorStatus().then(updateTimeSinceReading);
  }, 1000 * 60);

  React.useEffect(() => {
    updateTimeSinceReading();
  }, [lastReading]);

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: 0.5,
      marginBottom: 0.5,
    }}>
      <Typography variant="body1" sx={{
        marginRight: 3
      }}>
        Reading Status
      </Typography>
      <Typography variant="body2" color={isSensorOffline() ? 'error' : undefined}>
        {
          (() => {

            if (timeSinceLastReading === null) {
              return <Skeleton animation='wave' variant="circular" ><Check color='success' /></Skeleton>;
            }
            return <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              // Vertical centering
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              <Typography variant="body2" color={
                isSensorOffline() ? 'error' : 'GrayText'
              } sx={{
                marginRight: 1
              }}>
                {timeSinceLastReading}
              </Typography>
              {
                isSensorOffline() ? <PriorityHigh color='error' /> : <Check color='success' />
              }
            </Box>;
          })()
        }
      </Typography>
    </Box>
  );
}

registerProvider('any', GenericReadingStatusProvider);

function WeatherRackBatteryStatusProvider(props: SensorStatusIndicatorProviderProps) {
  if (props.sensorCollection !== 'weather_rack') {
    throw new Error(`Weather rack battery status provider only works with weather rack sensors`);
  }

  const considerSensorOfflineAfter = 1000 * 60 * 60; // 1 hour

  const [dateOfLatestReading, setDateOfLatestReading] = useState<Date | null>(null);

  const [sensorStatus, setSensorStatus] = useState<WeatherRackStatus | null>(null);

  const isSensorOffline = () => {
    if (dateOfLatestReading === null) {
      return false;
    }

    return new Date().getTime() - dateOfLatestReading.getTime() > considerSensorOfflineAfter;
  };

  const refreshStatus = async () => {
    try {
      const lastReading = await serverConnection.getDateOfLatestSensorReading(props.sensorCollection, props.sensorID);
      setDateOfLatestReading(lastReading);

      const statusBlob = await serverConnection.getSensorStatus();
      const status = statusBlob[props.sensorCollection][props.sensorID] as WeatherRackStatus;
      if (status === undefined) {
        throw new Error(`Failed to get weather rack status`);
      }
      setSensorStatus(status);
    }
    catch (e: any) {
      enqueueSnackbar(`Failed to get weather rack status: ${e.message}`, { variant: "error" });
      console.error(e);
    }
  };

  React.useEffect(() => {
    refreshStatus();
  }, []);

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: 0.5,
      marginBottom: 0.5
    }}>
      <Typography variant="body1">
        Battery Status
      </Typography>
      <Typography variant="body2">
        {
          !isSensorOffline() && <>
            {
              sensorStatus !== null && sensorStatus.batteryLow === false && <BatteryFull color='success' />
            }
            {
              sensorStatus !== null && sensorStatus.batteryLow === true && <Battery0Bar color='error' />
            }
            {
              sensorStatus === null && <Skeleton variant='circular' animation='wave'><Battery0Bar color='error' /></Skeleton>
            }
          </>
        }
        {
          isSensorOffline() && <>
            {
              sensorStatus !== null && sensorStatus.batteryLow ? <Battery0Bar color='error' /> : <QuestionMark color='warning' />
            }
          </>
        }
      </Typography>
    </Box>
  );
}

registerProvider('weather_rack', WeatherRackBatteryStatusProvider);


export type GenericSensorStatusWidget = {
  sensorCollection: string,
  sensorID: string,
  requestSensorFocus?: (sensorCollection: string, sensorID: string) => void,
  indicatorProviders?: SensorStatusIndicatorProvider[],
  rootElementProps?: CardProps['sx'];
  disableStatusIndicators?: boolean;
};
export function GenericSensorStatusWidget(props: GenericSensorStatusWidget) {
  if (props.sensorCollection === undefined) {
    throw new Error(`Sensor collection is undefined`);
  }

  const [sensorInfo, setSensorInfo] = useState<SensorInstanceInformation>();
  const [tags, setTags] = useState<Tag[]>();

  const indicatorProviders = getProviders(props.sensorCollection);

  const navigate = useNavigate();

  const viewOnMap = () => {
    if (props.requestSensorFocus) {
      props.requestSensorFocus(props.sensorCollection, props.sensorID);
      return;
    }
    const params = new URLSearchParams();
    params.set('focusedSensors', JSON.stringify([{ sensorCollection: 'weather_rack', sensorID: props.sensorID }]));
    navigate(`/map?${params.toString()}`);
  };

  const loadSensorInfo = async () => {
    try {
      const sensorInfo = await serverConnection.getSensorInstanceInformation(props.sensorCollection, props.sensorID);
      setSensorInfo(sensorInfo);
    }
    catch (e: any) {
      enqueueSnackbar(`Failed to get sensor information: ${e.message}`, { variant: "error" });
      console.error(e);
    }
  };

  const loadTags = async () => {
    try {
      const tags = await serverConnection.getSensorTags(props.sensorCollection, props.sensorID);
      setTags(tags);
    }
    catch (e: any) {
      enqueueSnackbar(`Failed to get tags: ${e.message}`, { variant: "error" });
      console.error(e);
    }
  };

  React.useEffect(() => {
    loadSensorInfo();
    loadTags();
  }, []);

  return (
    <Box sx={{
      ...props.rootElementProps,
      // Vertical centering
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',

    }}>
      <Box sx={{
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column'
      }}>
        <Tooltip title={props.sensorID}>
          <Typography variant="h5">
            {sensorInfo?.display_name ?? <Skeleton />}
          </Typography>
        </Tooltip>
        <Typography variant="caption">
          {props.sensorCollection}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', textAlign: 'center', justifyContent: 'center' }}>
        <Tooltip title='View On Map'>
          <IconButton onClick={viewOnMap}>
            <MapIcon />
          </IconButton>
        </Tooltip>
        {
          // If we're already at /sensorStatus, don't show the button.
          window.location.pathname !== '/sensorStatus' && <Tooltip title='Sensor Collection Status'>
            <IconButton onClick={() => {
              const params = new URLSearchParams();
              params.set('collection', 'weather_rack');
              navigate(`/sensorStatus?${params.toString()}`);
            }}>
              <Launch />
            </IconButton>
          </Tooltip>
        }
        <Tooltip title='Graph Data'>
          <IconButton onClick={() => {
            const params = new URLSearchParams();
            params.set('configurators', JSON.stringify([{
              sensorCollection: 'weather_rack',
              sensorNames: [props.sensorID],
              fields: []
            }]));
            navigate(`/sensorData?${params.toString()}`);
          }}>
            <AutoGraph />
          </IconButton>
        </Tooltip>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', textAlign: 'center', justifyContent: 'center' }}>
        {
          tags?.map((tag) => (
            <Tooltip title={tag.description}>
              <Chip label={tag.display_name} onClick={() => {
                if (tag.id.startsWith('growing_area')) {
                  const params = new URLMappedValueIntentBuilder().setBase64Value('targetAreas', [tag.id]);
                  navigate(`/map/details?${params.toString()}`);
                }
                else {
                  enqueueSnackbar(`Unknown tag type: ${tag.id}`, { variant: "error" });
                }
              }} sx={{
                margin: 0.25
              }} />
            </Tooltip>
          )) ?? <Skeleton />
        }
      </Box>
      {
        !props.disableStatusIndicators && indicatorProviders.map((Provider, index) => (
          <FailureBoundary key={index} failureHandler={(error, errorInfo, reset) => {
            return (
              <Alert severity="error">
                <Typography variant="body1">
                  {
                    `Status Indicator '${Provider.name}' Failed`
                  }
                </Typography>
                <Typography variant="body2">
                  {
                    error?.message
                  }
                </Typography>
              </Alert>
            );
          }}>
            <Provider sensorCollection={props.sensorCollection} sensorID={props.sensorID} />
          </FailureBoundary>
        ))
      }
    </Box>
  );
}

export type SensorStatusWidgetResolverProps = {
  sensorCollection: string;
  sensorID: string;
  resolvedComponentProps?: any;
};

export function SensorStatusWidgetResolver(props: SensorStatusWidgetResolverProps) {
  //<WeatherRackStatusWidget sensorID={ props.sensorID } />
  return <FailureBoundary failureHandler={(error, errorInfo, reset) => {
    return (
      <>
        <Alert severity="error">
          <AlertTitle>Critical Component Failure
            {
              props.sensorCollection !== undefined && <> in component '{props.sensorCollection}'</>
            }
          </AlertTitle>
          <Typography variant="body1">
            This component encountered an unhandled error and could not recover.
          </Typography>
          <Typography variant='subtitle1'>
            {error.message}
          </Typography>
        </Alert>
      </>
    );
  }}>
    {
      (() => {
        if (props.sensorCollection === 'weather_rack') {
          return (
            <GenericSensorStatusWidget sensorID={props.sensorID} {...props.resolvedComponentProps} />
          );
        }
        else {
          return <Alert severity='error'>
            <AlertTitle>Unknown Sensor Collection</AlertTitle>
            <Typography variant='body1'>
              The sensor collection '{props.sensorCollection}' is not known to the system.
            </Typography>
          </Alert>;
        }
      })()
    }
  </FailureBoundary>;
}

function WeatherRackSensorStatusCollectionContainer(props: SensorCollectionWidgetContainerProps) {
  const [uniqueSensorIDs, setUniqueSensorIDs] = useState<string[]>();

  const refreshUniqueSensorIDs = async () => {
    const sources = await serverConnection.getUniqueSourcesFromSensorCollection(props.sensorCollection);
    setUniqueSensorIDs(sources);
  };

  React.useEffect(() => {
    refreshUniqueSensorIDs();
  }, []);


  return <Masonry columns={{
    xs: 1,
    sm: 2,
    md: 2,
    lg: 3,
    xl: 3,
  }}>
    <Denullifier>
      {
        uniqueSensorIDs?.map((sensorID) => (<SensorStatusWidgetResolver sensorCollection={props.sensorCollection} sensorID={sensorID} /> as ReactElement<any, any>))
      }
    </Denullifier>
  </Masonry>;
}

type UnknownSensorStatusWidgetProps = {
  sensorCollection: string;
  sensorID: string;
};

function UnknownSensorStatusWidget(props: UnknownSensorStatusWidgetProps) {
  const [lastReading, setLastReading] = useState<Date>(new Date());
  const [timeSinceLastReading, setTimeSinceLastReading] = useState<string | null>(null);

  const considerSensorOfflineAfter = 1000 * 60 * 60 * 24;

  const refreshSensorStatus = async () => {
    try {
      const lastReading = await serverConnection.getDateOfLatestSensorReading(props.sensorCollection, props.sensorID);
      setLastReading(lastReading);

      const timeSinceLastReading = (Date.now() - lastReading.getTime());

      if (timeSinceLastReading < 0 && false) {
        setTimeSinceLastReading('live');
      }
      else {
        setTimeSinceLastReading(`${humanizer.humanize(timeSinceLastReading,
          {
            largest: 2,
          })} ago`);
      }
    }
    catch (e: any) {
      enqueueSnackbar(`Failed to get sensor status: ${e.message}`, { variant: "error" });
      console.error(e);
    }
  };

  React.useEffect(() => {
    refreshSensorStatus();
  }, []);

  return (
    <TableRow sx={{
      backgroundColor: new Date().getTime() - lastReading.getTime() > considerSensorOfflineAfter ? 'error.light' : undefined,
    }}>
      <TableCell>
        {props.sensorID}
      </TableCell>
      <TableCell>
        {
          timeSinceLastReading ?? <Skeleton />
        }
      </TableCell>
    </TableRow>
  );
}

type UnknownSensorCollectionWidgetContainerProps = {
  sensorCollection: string;
};

function UnknownSensorCollectionWidgetContainer(props: UnknownSensorCollectionWidgetContainerProps) {
  const [uniqueSensorIDs, setUniqueSensorIDs] = useState<string[]>();

  const refreshUniqueSensorIDs = async () => {
    const sources = await serverConnection.getUniqueSourcesFromSensorCollection(props.sensorCollection);
    setUniqueSensorIDs(sources);
  };

  React.useEffect(() => {
    refreshUniqueSensorIDs();
  }, [props.sensorCollection]);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            Sensor ID
          </TableCell>
          <TableCell>
            Last Reading
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          uniqueSensorIDs?.map((sensorID) => (<UnknownSensorStatusWidget sensorCollection={props.sensorCollection} sensorID={sensorID} />))
        }
      </TableBody>
    </Table>
  );
}

type SensorCollectionWidgetContainerProps = {
  sensorCollection: string;
};

function SensorCollectionWidgetContainer(props: SensorCollectionWidgetContainerProps) {
  const TargetComponent = (() => {
    switch (props.sensorCollection) {
      case "weather_rack":
        return WeatherRackSensorStatusCollectionContainer;
      default:
        return UnknownSensorCollectionWidgetContainer;
    }
  })();

  return (
    <FailureBoundary failureHandler={(error, errorInfo, reset) => {
      return (
        <>
          <Alert severity="error">
            <AlertTitle>Critical Component Failure
              {
                props.sensorCollection !== undefined && <> in component '{props.sensorCollection}'</>
              }
            </AlertTitle>
            <Typography variant="body1">
              This component encountered an unhandled error and could not recover.
            </Typography>
            <Typography variant='subtitle1'>
              {error.message}
            </Typography>
          </Alert>
        </>
      );
    }}>
      <TargetComponent sensorCollection={props.sensorCollection} />
    </FailureBoundary>
  );

}


export type SensorStatusPageContentProps = {};
export function SensorStatusPageContent(props: SensorStatusPageContentProps) {
  const [availableSensorCollections, setAvailableSensorCollections] = useState<string[]>(["weather_rack", "kkm_k6p"]);
  const [allSensorCollections, setAllSensorCollections] = useState<string[]>([]);
  const [selectedSensorCollection, setSelectedSensorCollection] = useURLMappedStateValue<string | null>('collection', null, null);

  const refreshSensorCollections = async () => {
    const sensorCollections = await serverConnection.getSensorCollections();
    setAvailableSensorCollections(sensorCollections);
    setAllSensorCollections(sensorCollections);
  };

  React.useEffect(() => {
    if (selectedSensorCollection === null) {
      setAvailableSensorCollections(allSensorCollections);
    }
    else {
      if (availableSensorCollections.length === 1) {
        return;
      }
      setAvailableSensorCollections([selectedSensorCollection]);
    }
  }, [selectedSensorCollection, availableSensorCollections, allSensorCollections]);

  React.useEffect(() => {
    refreshSensorCollections();
  }, []);

  return (
    <>
      <Collapse in={true}>
        <List dense>
          <TransitionGroup>
            {
              availableSensorCollections?.map((sensorCollection, index) => (
                <Collapse key={index}>
                  <ListItemButton sx={{
                    backgroundColor: selectedSensorCollection === sensorCollection ? 'primary.dark' : undefined,
                  }} onClick={() => setSelectedSensorCollection(sensorCollection)}>
                    <ListItemAvatar>
                      <Avatar>
                        <ImageIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText>
                      {sensorCollection}
                    </ListItemText>
                  </ListItemButton>
                </Collapse>
              ))
            }
          </TransitionGroup>
          <Collapse in={selectedSensorCollection !== null}>
            <ListItemButton onClick={() => {
              setSelectedSensorCollection(null);
            }} key='resetter'>
              Back
            </ListItemButton>
          </Collapse>
        </List>
      </Collapse>
      <Collapse in={selectedSensorCollection !== null}>
        {
          selectedSensorCollection !== null && <SensorCollectionWidgetContainer sensorCollection={selectedSensorCollection} />
        }
      </Collapse>
    </>
  );
}


export type SensorStatusPageProps = {};
export function SensorStatusPage(props: SensorStatusPageProps) {
  return <PageRoot headerProps={{
    title: "Sensor Status",
  }}>
    <PageBody scrollable>
      {/* <Box sx={ {
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
            } }>
                <WeatherRackStatusWidget sensorID="WS3" />
            </Box> */}
      <SensorStatusPageContent />
    </PageBody>
  </PageRoot>;
}
