import { Backdrop, Box, Collapse, LinearProgress, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Typography } from "@mui/material";
import StreamIcon from '@mui/icons-material/Stream';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import DatasetIcon from '@mui/icons-material/Dataset';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SettingsIcon from '@mui/icons-material/Settings';
import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { serverConnection } from "../data/ServerConnection";
import { getConfigurationValue } from "../data/local-storage/Configuration";
import { DevOnly } from "../shared/DevOnly";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { RequireUserScope } from "./RequireUserScope";
import { IMapArea, IPlugin } from "../../serverShared/SensorDefinitions";
import { TransitionGroup } from "react-transition-group";
import StorageIcon from '@mui/icons-material/Storage';
import HomeIcon from '@mui/icons-material/Home';

type NavigationSidebarEntry = {
    name: string;
    path: string;
    icon?: JSX.Element;
};

const navigationSidebarEntries: NavigationSidebarEntry[] = [
    {
        name: "Home",
        path: "/",
        icon: <HomeIcon />
    },
    {
        name: "Real Time Map",
        path: "/map",
        icon: <StreamIcon />
    },
    {
        name: "3D View",
        path: "/3D",
        icon: <ViewInArIcon />
    },
    {
        name: "Sensor Data",
        path: "/sensorData",
        icon: <DatasetIcon />
    },
    {
        name: "User Settings",
        path: "/user/settings",
        icon: <AutoGraphIcon />
    }
];

export function addNavigationSidebarEntry(entry: NavigationSidebarEntry)
{
    navigationSidebarEntries.push(entry);
}

serverConnection.getPlugins().then((plugins) =>
{
    for (const plugin of plugins)
    {
        addNavigationSidebarEntry({
            name: plugin.name,
            path: `/plugin/${plugin.id}`,
            icon: <SettingsIcon />
        });
    }
});

serverConnection.getUserScopes().then((scopes) =>
{
    if (scopes.some(x => x === 'databaseAdministration'))
    {
        addNavigationSidebarEntry({
            name: "Database Administration",
            path: `/database`,
            icon: <StorageIcon />
        });
    }
    if (scopes.some(x => x === 'dev'))
    {
        addNavigationSidebarEntry({
            name: "Configuration",
            path: `/dev/cfg`,
            icon: <SettingsIcon />
        });

        addNavigationSidebarEntry({
            name: "Network Checking",
            path: `/dev/network`,
            icon: <SettingsIcon />
        });

        addNavigationSidebarEntry({
            name: "Testing",
            path: `/testing`,
            icon: <SettingsIcon />
        });
    }
});

/**
 * The content of the navigation sidebar.
 * @returns A sidebar that contains the navigation links for the application.
 */
export default function NavigationSidebarContent()
{
    const navigateWrapped = useNavigate();

    const location = useLocation();

    const [ plugins, setPlugins ] = React.useState<IPlugin[] | undefined>();

    useEffect(() =>
    {
        (async () =>
        {
            const plugins = await serverConnection.getPlugins();
            setPlugins(plugins);
        })();
    }, []);

    // Navigate to a path, and show the loading banner.
    const navigate = (path: string) =>
    {
        navigateWrapped(path);
    };

    const TransitionComponent = Collapse;

    return (<Box
        sx={ { width: '250' } }
        role="presentation"
    >
        <List>
            <TransitionGroup>
                {
                    navigationSidebarEntries.filter(x =>
                    {
                        // Don't show the entry if we're already on the page
                        return x.path !== location.pathname;
                    }).map((entry, index) =>
                    {
                        const { name, path, icon } = entry;
                        const key = `${name}-${path}`;
                        return (
                            <TransitionComponent key={ key }>
                                <ListItem disablePadding>
                                    <ListItemButton onClick={ () =>
                                    {
                                        navigate(path);
                                    } }>
                                        <ListItemIcon>
                                            {
                                                icon ?? <SettingsIcon />
                                            }
                                        </ListItemIcon>
                                        <ListItemText primary={ name } />
                                    </ListItemButton>
                                </ListItem>
                            </TransitionComponent>

                        );
                    })
                }
                {/* <ListItem disablePadding>
                    <ListItemButton onClick={ () =>
                    {
                        navigate('/map');
                    } }>
                        <ListItemIcon>
                            <StreamIcon />
                        </ListItemIcon>
                        <ListItemText primary={ "Real Time Map" } />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton onClick={ () =>
                    {
                        navigate('/3D');
                    } }>
                        <ListItemIcon>
                            <ViewInArIcon />
                        </ListItemIcon>
                        <ListItemText primary={ "3D View" } />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton onClick={ () =>
                    {
                        navigate('/sensorData');
                    } }>
                        <ListItemIcon>
                            <DatasetIcon />
                        </ListItemIcon>
                        <ListItemText primary={ "Sensor Data" } />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton onClick={ () =>
                    {
                        navigate('/sensorStatus');
                    } }>
                        <ListItemIcon>
                            <DatasetIcon />
                        </ListItemIcon>
                        <ListItemText primary={ "Sensor Status" } />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton onClick={ () =>
                    {
                        navigate('/sap/new');
                    } }>
                        <ListItemIcon>
                            <AssessmentIcon />
                        </ListItemIcon>
                        <ListItemText primary={ "Sap Analysis" } />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton onClick={ () =>
                    {
                        navigate('/user/settings');
                    } }>
                        <ListItemIcon>
                            <AutoGraphIcon />
                        </ListItemIcon>
                        <ListItemText primary={ "User Settings" } />
                    </ListItemButton>
                </ListItem>
                <DevOnly>
                    <ListItem disablePadding>
                        <ListItemButton onClick={
                            () => navigate('/dev/cfg')
                        }>
                            <ListItemIcon>
                                <SettingsIcon />
                            </ListItemIcon>
                            <ListItemText primary={ "Configuration" } />
                        </ListItemButton>
                    </ListItem>
                </DevOnly>
                <ListItem disablePadding>
                    <ListItemButton onClick={
                        () => navigate('/dev/buildinfo')
                    }>
                        <ListItemIcon>
                            <SettingsIcon />
                        </ListItemIcon>
                        <ListItemText primary={ "Build Information" } />
                    </ListItemButton>
                </ListItem>
                <DevOnly>
                    <ListItem disablePadding>
                        <ListItemButton onClick={
                            () => navigate('/dev/network')
                        }>
                            <ListItemIcon>
                                <SettingsIcon />
                            </ListItemIcon>
                            <ListItemText primary={ "Network Checking [Dev]" } />
                        </ListItemButton>
                    </ListItem>
                </DevOnly>
                <DevOnly>
                    <ListItem disablePadding>
                        <ListItemButton onClick={
                            () =>
                            {
                                navigate('/testing');
                            }
                        }>
                            <ListItemIcon>
                                <SettingsIcon />
                            </ListItemIcon>
                            <ListItemText primary={ "Testing" } />
                        </ListItemButton>
                    </ListItem>
                </DevOnly>
                <RequireUserScope scopes={ [ "dev" ] }>
                    <ListItem disablePadding>
                        <ListItemButton onClick={ () =>
                        {
                            navigate('/dev/serviceworkermanager');
                        } }>
                            <ListItemIcon>
                                <ErrorOutlineIcon />
                            </ListItemIcon>
                            <ListItemText primary={ "Service Worker Manager" } />
                        </ListItemButton>
                    </ListItem>
                </RequireUserScope>
                <RequireUserScope scopes={ [ "dev" ] }>
                    <ListItem disablePadding>
                        <ListItemButton onClick={ () =>
                        {
                            navigate('/app/notices');
                        } }>
                            <ListItemIcon>
                                <ErrorOutlineIcon />
                            </ListItemIcon>
                            <ListItemText primary={ "Application Notices" } />
                        </ListItemButton>
                    </ListItem>
                </RequireUserScope> */}
            </TransitionGroup>
        </List>
    </Box>);
}