import { Accordion, AccordionDetails, AccordionSummary, Alert, AlertTitle, Badge, Box, Button, Card, CardHeader, Checkbox, Chip, CircularProgress, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fade, FormControlLabel, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, Paper, Slide, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import { serverConnection } from "../data/ServerConnection";
import { usePromise } from "../misc/PromiseHook";
import PageRoot, { LeftMenu, PageBody } from "../shared/BasicPageLayout";
import { RequireUserScope } from "../shared/RequireUserScope";
import React, { useEffect, useId, useState } from "react";
import { useURLMappedBase64Value, useURLMappedStateValue } from "../misc/URLMappedStateValue";
import { SensorCollectionFieldInformation, SensorCollectionInformation, SensorInstanceInformation, Tag } from "../../serverShared/SensorDefinitions";
import { TransitionGroup } from "react-transition-group";
import { GroupBox } from "../shared/GroupBox";
import Draggable from "react-draggable";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import delay from "../misc/Delay";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CloseIcon from '@mui/icons-material/Close';
import { enqueueSnackbar } from "notistack";
import { Masonry } from "@mui/lab";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EditNoteIcon from '@mui/icons-material/EditNote';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ThemeProvider } from "@emotion/react";
import { availableThemes } from "../shared/themes/ThemeManager";
const administrationPlugins: DatabaseAdministrationPlugin[] = [];
type DatabaseAdministrationPluginTargetProps = {};

function SensorInstanceMetadataEditor(props: { base: SensorInstanceInformation; onChange?: (newSensorInstance: SensorInstanceInformation) => void | Promise<void>; }) {
  const [instanceUniqueIdentifier, setInstanceUniqueIdentifier] = React.useState<string | null>(props.base.instance_unique_identifier);
  const [hasInstanceUniqueIdentifierChanged, setHasInstanceUniqueIdentifierChanged] = React.useState(false);
  const [displayName, setDisplayName] = React.useState<string | null>(props.base.display_name);
  const [latitude, setLatitude] = React.useState<number | null>(props.base.latitude);
  const [longitude, setLongitude] = React.useState<number | null>(props.base.longitude);
  const [altitudeOrthometric, setAltitudeOrthometric] = React.useState<number | null>(props.base.altitude_orthometric);
  const [tags, setTags] = usePromise<(Tag & {
    applied: boolean;
  })[]>(async () => {
    const allTags = await serverConnection.getAllTags();
    return allTags.map(tag => ({ ...tag, applied: props.base.tags.map(x => x.id).includes(tag.id) }));
  });

  const [applyPromise, setApplyPromise, resetApplyPromise] = usePromise<void>();

  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);

  React.useEffect(() => {
    setHasInstanceUniqueIdentifierChanged(instanceUniqueIdentifier !== props.base.instance_unique_identifier);
  }, [props.base, instanceUniqueIdentifier]);

  const applyChanges = async () => {
    if (tags.state !== 'fulfilled') {
      enqueueSnackbar('Tags are not loaded yet', { variant: 'error' });
      return;
    }
    if (props.onChange !== undefined) {
      await props.onChange({
        ...props.base,
        instance_unique_identifier: instanceUniqueIdentifier,
        display_name: displayName,
        latitude,
        longitude,
        altitude_orthometric: altitudeOrthometric,
        tags: tags.value.filter(x => x.applied)
      });
    }
  };

  return (
    <>
      <Dialog open={showDeleteDialog} onClose={() => setShowDeleteDialog(false)}>
        <DialogTitle>Delete Sensor Instance?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete '<code>{props.base.instance_data_source}/{props.base.instance_unique_identifier}</code>'? This cannot be undone.
            This action will <b>not</b> delete any data associated with this sensor instance, but it will make it inacessible.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDeleteDialog(false)}>Cancel</Button>
          <Button color='error' variant='contained' onClick={async () => {
            try {
              const dbAdmin = await serverConnection.getDatabaseAdministrationController();
              await dbAdmin.deleteSensor(props.base);
            }
            catch (e: any) {
              enqueueSnackbar(e.message, { variant: 'error' });
            }
            setShowDeleteDialog(false);
          }
          }>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Card>
        <Collapse in={hasInstanceUniqueIdentifierChanged}>
          <Alert severity='warning'>
            <AlertTitle>Warning</AlertTitle>
            Changing the instance unique identifier could cause a new sensor to be created, or an existing sensor to be overwritten. This could cause data loss. Please be careful.
          </Alert>
        </Collapse>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography>Instance Unique Identifier</Typography>
              </TableCell>
              <TableCell>
                <TextField value={instanceUniqueIdentifier ?? ''} onChange={(e) => setInstanceUniqueIdentifier(e.target.value)} variant='standard' />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>Display Name</Typography>
              </TableCell>
              <TableCell>
                <TextField value={displayName ?? ''} onChange={(e) => setDisplayName(e.target.value)} variant='standard' />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>Latitude</Typography>
              </TableCell>
              <TableCell>
                <TextField value={latitude ?? ''} onChange={(e) => setLatitude(parseFloat(e.target.value))} variant='standard' />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>Longitude</Typography>
              </TableCell>
              <TableCell>
                <TextField value={longitude ?? ''} onChange={(e) => setLongitude(parseFloat(e.target.value))} variant='standard' />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>Altitude Orthometric</Typography>
              </TableCell>
              <TableCell>
                <TextField value={altitudeOrthometric ?? ''} onChange={(e) => setAltitudeOrthometric(parseFloat(e.target.value))} variant='standard' />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        {
          tags.state === 'fulfilled' && <GroupBox title='Tags' disableTitleAnimation>
            {
              tags.value.map(x => (
                <Tooltip key={x.id} title={x.description}>
                  <Chip sx={{
                    margin: '0.25em'
                  }} key={x.id} label={x.display_name} icon={
                    x.applied ? <CheckIcon /> : <CloseIcon />
                  }
                    variant={
                      x.applied ? 'filled' : 'outlined'
                    }
                    color={x.applied ? 'primary' : undefined} onClick={() => {
                      let newTags = [...tags.value];
                      newTags.find(predicate => predicate.id === x.id)!.applied = !x.applied;
                      setTags(Promise.resolve(newTags));
                    }} />
                </Tooltip>
              ))
            }
          </GroupBox>
        }
        <Box sx={{
          // Horizontally center
          display: 'flex',
          justifyContent: 'center',
          marginTop: '1em',
          // Vertically center
          alignItems: 'center',
          // Item spacing
          gap: '1em',
          // Force items on separate lines
          flexWrap: 'wrap'
        }}>
          <IconButton sx={{
            color: applyPromise.state === 'fulfilled' ? 'success.main' : applyPromise.state === 'rejected' ? 'error.main' : undefined,
            borderColor: applyPromise.state === 'fulfilled' ? 'success.main' : applyPromise.state === 'rejected' ? 'error.main' : undefined,
            borderWidth: '2px',
            borderStyle: 'solid'
          }} disabled={
            applyPromise.state === 'pending'
          } onClick={async () => {
            const promise = applyChanges();
            setApplyPromise(promise);
            promise.finally(() => {
              delay(10000).then(() => resetApplyPromise());
            });
          }}>
            <SaveIcon />
          </IconButton>
          <IconButton sx={{
            color: sx => sx.palette.error.main,
            borderColor: sx => sx.palette.error.main,
            borderWidth: '2px',
            borderStyle: 'solid'
          }} onClick={() => {
            setShowDeleteDialog(true);
          }}>
            <DeleteForeverIcon />
          </IconButton>
        </Box>
        <Box sx={{
          // Horizontally center
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '1em',
          // Vertically center
          alignItems: 'center',
          // Item spacing
          gap: '1em',
          // Force items on separate lines
          flexWrap: 'wrap',
        }}>

          <TransitionGroup>
            {
              applyPromise.state === 'pending' && <Collapse in={true} key='promise-pending-dialog'>
                <Alert severity='info'>
                  <AlertTitle>Saving</AlertTitle>
                  Please wait while the changes are applied
                </Alert>
              </Collapse>
            }
            {
              applyPromise.state === 'fulfilled' && <Collapse in={true} key='promise-fulfilled-dialog'>
                <Alert severity='success'>
                  <AlertTitle>Success</AlertTitle>
                  Changes applied successfully
                </Alert>
              </Collapse>
            }
            {
              applyPromise.state === 'rejected' && <Collapse in={true} key='promise-rejected-dialog'>
                <Alert severity='error'>
                  <AlertTitle>Error</AlertTitle>
                  {
                    applyPromise.error.message ?? 'Unknown error'
                  }
                </Alert>
              </Collapse>
            }
          </TransitionGroup>
        </Box>
      </Card >
    </>
  );
}

function SensorInstanceEditor(props: DatabaseAdministrationPluginTargetProps & { sensorCollection: SensorCollectionInformation, sensorInstance: SensorInstanceInformation; }) {
  return (
    <>
      <Typography variant='h6'>
        {
          props.sensorInstance.display_name
        }
      </Typography>

      <SensorInstanceMetadataEditor base={props.sensorInstance} onChange={async newValue => {
        const dbAdmin = await serverConnection.getDatabaseAdministrationController();

        await dbAdmin.upsertSensor(newValue);
      }} />
    </>
  );
}

function SensorInstanceEditorHost(props: DatabaseAdministrationPluginTargetProps) {
  const [sensorCollections] = usePromise(() => serverConnection.getSensorCollectionsV2());
  const [selectedSensorCollection, setSelectedSensorCollection] = React.useState<SensorCollectionInformation | null>(null);
  const [availableSensors, setAvailableSensors] = usePromise<SensorInstanceInformation[]>();
  const [selectedSensorInstance, setSelectedSensorInstance] = React.useState<SensorInstanceInformation | null>(null);

  const updateAvailableSensors = React.useCallback(() => {
    if (selectedSensorCollection === null) {
      return;
    }
    setAvailableSensors((async () => {
      const sensorInstances = await serverConnection.getUniqueSourcesFromSensorCollection(selectedSensorCollection.data_source_name);

      // Use Promise.all to fetch SensorInstanceInformation concurrently
      const promises = sensorInstances.map(async (sensorInstance) => {
        return serverConnection.getSensorInstanceInformation(selectedSensorCollection.data_source_name, sensorInstance);
      });

      const result = await Promise.all(promises);
      return result;
    })());
  }, [selectedSensorCollection]);

  React.useEffect(() => {
    if (selectedSensorCollection === null) {
      return;
    }

    if (selectedSensorInstance === null) {
      updateAvailableSensors();
    }

  }, [selectedSensorInstance]);

  React.useEffect(() => {
    updateAvailableSensors();
  }, [selectedSensorCollection]);

  return (
    <>
      <Typography variant='h6'>Sensor Instance Editor</Typography>
      {
        sensorCollections.state === 'fulfilled' ? (
          <TransitionGroup>
            {
              selectedSensorCollection === null && selectedSensorInstance === null && <Collapse key={'sensor-collection-list'}>
                <Masonry columns={5} spacing={1}>
                  {
                    sensorCollections.value.map(collection => (
                      <Chip key={collection.data_source_name} label={collection.display_name} onClick={() => setSelectedSensorCollection(collection)} />
                    ))
                  }
                </Masonry>
              </Collapse>
            }
            {
              selectedSensorCollection !== null && selectedSensorInstance === null && <Collapse key={'sensor-collection-editor'}>
                <>
                  <Button onClick={() => setSelectedSensorCollection(null)}>Back</Button>
                  <Typography variant='h6'>{selectedSensorCollection.display_name}</Typography>
                  <Masonry columns={5} spacing={1}>
                    {
                      availableSensors.state === 'fulfilled' ? (
                        availableSensors.value.map(sensor => (
                          <Chip key={sensor.instance_unique_identifier} label={sensor.display_name} onClick={() => setSelectedSensorInstance(sensor)} />
                        ))
                      ) : <CircularProgress />
                    }
                  </Masonry>
                  <Button onClick={() => {
                    setSelectedSensorInstance({
                      sensor_collection: selectedSensorCollection.data_source_name,
                      instance_data_source: '',
                      instance_unique_identifier: '',
                      display_name: '',
                      latitude: null,
                      longitude: null,
                      altitude_orthometric: null,
                      tags: [],
                      getSensorCollectionInformation: null!
                    });
                    console.log('Creating new sensor instance');
                  }}>
                    Add Sensor Instance
                  </Button>
                </>
              </Collapse>
            }
            {
              selectedSensorCollection !== null && selectedSensorInstance !== null && <Collapse key={'sensor-instance-editor'}>
                <Button onClick={() => setSelectedSensorInstance(null)}>Back</Button>
                <SensorInstanceEditor sensorCollection={selectedSensorCollection!} sensorInstance={selectedSensorInstance!} />
              </Collapse>
            }
          </TransitionGroup>
        ) : (
          <CircularProgress />
        )
      }
    </>
  );
}

registerAdministrationPlugin({
  pluginID: 'sensor-instance-editor',
  displayName: 'Sensor Instance Editor',
  description: 'Edit the sensor instances in the database',
  target: SensorInstanceEditorHost
});

/**
 export interface SensorCollectionInformation
{
    data_source_name: string;
    description: string;
    display_name: string;
    instance_database_name: string;
    readings_database_name: string;
    unique_identifier_field: string;
    timestamp_field_name: string;
    timestamp_field_format: string;
    fields: SensorCollectionFieldInformation[];
    tags: Tag[];
    reading_rate: number;
}
 */

/**export interface SensorCollectionFieldInformation
{
    field_name: string;
    display_name: string;
    datatype?: string;
    kind?: string;
    tags: Tag[];
    units_metric?: string;
    units_imperial?: string;
} */

function DataSourceFieldEditor(props: DatabaseAdministrationPluginTargetProps & { field: SensorCollectionFieldInformation; onChange?: (newField: SensorCollectionFieldInformation) => void | Promise<void>; requestDelete?: () => void | Promise<void>; }) {
  if (props.field.units_imperial === undefined) {
    props.field.units_imperial = '';
  }
  if (props.field.units_metric === undefined) {
    props.field.units_metric = '';
  }

  const [fieldName, setFieldName] = React.useState<string | null>(props.field.field_name ?? '[UNDEFINED]');
  const [displayName, setDisplayName] = React.useState<string | null>(props.field.display_name ?? '[UNDEFINED]');
  const [datatype, setDatatype] = React.useState<string | null>(props.field.datatype ?? 'unknown');
  const [kind, setKind] = React.useState<string | null>(props.field.kind ?? 'unknown');
  const [unitsMetric, setUnitsMetric] = React.useState<string | null>(props.field.units_metric ?? '');
  const [unitsImperial, setUnitsImperial] = React.useState<string | null>(props.field.units_imperial ?? '');
  const [tags, setTags] = usePromise<(Tag & {
    applied: boolean;
  })[]>(async () => {
    const allTags = await serverConnection.getAllTags();
    if (props.field.tags === undefined) {
      props.field.tags = [];
    }
    return allTags.map(tag => ({ ...tag, applied: props.field.tags.map(x => x.id).includes(tag.id) }));
  });


  const [expanded, setExpanded] = React.useState(false);

  const [changedReason, setChangedReason] = React.useState('');

  useEffect(() => {
    setFieldName(props.field.field_name ?? '[UNDEFINED]');
    setDisplayName(props.field.display_name ?? '[UNDEFINED]');
    setDatatype(props.field.datatype ?? '[UNDEFINED]');
    setKind(props.field.kind ?? '[UNDEFINED]');
    setUnitsMetric(props.field.units_metric ?? '');
    setUnitsImperial(props.field.units_imperial ?? '');
  }, [props.field]);

  const [isEdited, setIsEdited] = React.useState(false);

  useEffect(() => {
    let isEdited = false;
    let debugEditedReason = '';
    if (fieldName !== props.field.field_name) {
      isEdited = true;
      debugEditedReason += 'fieldName';
    }
    if (displayName !== props.field.display_name) {
      isEdited = true;
      debugEditedReason += 'displayName';
    }
    if (datatype !== props.field.datatype) {
      isEdited = true;
      debugEditedReason += 'datatype';
    }
    if (kind !== props.field.kind) {
      isEdited = true;
      debugEditedReason += 'kind';
    }
    if (unitsMetric !== props.field.units_metric) {
      isEdited = true;
      debugEditedReason += 'unitsMetric';
    }
    if (unitsImperial !== props.field.units_imperial) {
      isEdited = true;
      debugEditedReason += 'unitsImperial';
    }

    {
      if (tags.state === 'fulfilled') {
        const newTags = tags.value.filter(x => x.applied).sort(
          (a, b) => a.id.localeCompare(b.id)
        ).map(x => x.id).join(',');
        const oldTags = props.field.tags.sort(
          (a, b) => a.id.localeCompare(b.id)
        ).map(x => x.id).join(',');

        const change = newTags !== oldTags;
        if (change) {
          isEdited = true;
          debugEditedReason += 'tags';
        }
      }
    }

    setIsEdited(isEdited);
    setChangedReason(debugEditedReason);
  }, [fieldName, displayName, datatype, kind, unitsMetric, unitsImperial, tags, props.field]);


  const choices = {
    kind: [
      'reported',
      'calculated',
      'unknown'
    ],
    datatype: [
      'string',
      'number',
      'boolean',
      'date',
      'unknown'
    ]
  };
  const textFieldSX = {
    width: '100%',
  };

  const applyChanges = () => {
    if (tags.state !== 'fulfilled') {
      enqueueSnackbar('Tags are not loaded yet', { variant: 'error' });
      return;
    }

    setExpanded(false);

    if (props.onChange) {
      return Promise.resolve(props.onChange({
        field_name: fieldName!,
        display_name: displayName!,
        datatype: datatype!,
        kind: kind!,
        tags: tags!.value.filter(x => x.applied),
        units_metric: unitsMetric!,
        units_imperial: unitsImperial!
      }));
    }

    return Promise.resolve();
  };

  return (
    <Accordion variant="outlined" TransitionProps={{ unmountOnExit: true }} expanded={expanded} >
      <AccordionSummary onClick={
        () => setExpanded(!expanded)
      }>
        <Tooltip title={
          'Changed'
        }>
          <Badge badgeContent={
            isEdited ? '!' : undefined
          } color='error' variant={
            isEdited ? 'dot' : undefined
          }>
            <Typography>{displayName}</Typography>
          </Badge>
        </Tooltip>
      </AccordionSummary>
      <AccordionDetails>
        <RequireUserScope scopes={['dev']}>
          <Typography>
            Changed:
            {
              changedReason
            }
          </Typography>
        </RequireUserScope>
        <Table>
          <TableBody>
            <TableRow >
              <TableCell>
                <Typography>Field Name</Typography>
              </TableCell>
              <TableCell>
                <TextField sx={textFieldSX} value={fieldName} onChange={(e) => setFieldName(e.target.value)} variant='standard' />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>Display Name</Typography>
              </TableCell>
              <TableCell>
                <TextField sx={textFieldSX} value={displayName} onChange={(e) => setDisplayName(e.target.value)} variant='standard' />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>Data Type</Typography>
              </TableCell>
              <TableCell>
                {
                  choices.datatype.map(choice => (
                    <Chip key={choice} label={choice} onClick={() => setDatatype(choice)} sx={{
                      margin: '0.25em'
                    }} variant={
                      choice === datatype ? 'filled' : 'outlined'
                    } color={
                      choice === datatype ? 'primary' : undefined
                    } />
                  ))
                }
                <Collapse in={!choices.datatype.includes(datatype ?? '') && datatype.length > 0}>
                  <Alert severity='error'>
                    Unknown value <code>{datatype}</code>
                  </Alert>
                </Collapse>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>Kind</Typography>
              </TableCell>
              <TableCell>
                {
                  choices.kind.map(choice => (
                    <Chip key={choice} label={choice} onClick={() => setKind(choice)} sx={{
                      margin: '0.25em'
                    }} variant={
                      choice === kind ? 'filled' : 'outlined'
                    } color={
                      choice === kind ? 'primary' : undefined
                    } />
                  ))
                }

                <Collapse in={!choices.kind.includes(kind ?? '') && kind.length > 0}>
                  <Alert severity='error'>
                    Unknown value <code>{kind}</code>
                  </Alert>
                </Collapse>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>Metric Units</Typography>
              </TableCell>
              <TableCell>
                <TextField sx={textFieldSX} value={props.field.units_metric} onChange={(e) => props.onChange?.({ ...props.field, units_metric: e.target.value })} variant='standard' />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>Imperial Units</Typography>
              </TableCell>
              <TableCell>
                <TextField sx={textFieldSX} value={props.field.units_imperial} onChange={(e) => props.onChange?.({ ...props.field, units_imperial: e.target.value })} variant='standard' />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>Tags</Typography>
              </TableCell>
              <TableCell sx={{
                width: '70%'
              }}>
                {
                  tags.state === 'rejected' && <Alert severity='error'>
                    <AlertTitle>Error</AlertTitle>
                    {
                      tags.error.message
                    }
                  </Alert>
                }
                {
                  tags.state === 'fulfilled' && tags.value.map(x => (
                    <Tooltip key={x.id} title={x.description}>
                      <Chip sx={{
                        margin: '0.25em'
                      }} key={x.id} label={x.display_name} icon={
                        x.applied ? <CheckIcon /> : <CloseIcon />
                      }
                        variant={
                          x.applied ? 'filled' : 'outlined'
                        }
                        color={x.applied ? 'primary' : undefined} onClick={() => {
                          let newTags = [...tags.value];
                          newTags.find(predicate => predicate.id === x.id)!.applied = !x.applied;
                          setTags(Promise.resolve(newTags));
                        }} />
                    </Tooltip>
                  ))
                }
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Box sx={{
          // Horizontally center
          display: 'flex',
          justifyContent: 'center',
          marginTop: '1em',
          // Set item spacing
          gap: '1em',
        }}>
          <Button disabled={!isEdited} color='success' variant='contained' startIcon={<SaveIcon />} onClick={applyChanges}>
            Apply
          </Button>
          <Button disabled={props.requestDelete === undefined} startIcon={<DeleteForeverIcon />} color='error' variant='contained' onClick={() => {
            setExpanded(false);
            delay(750).then(() => props.requestDelete?.());
          }}>
            Delete
          </Button>
        </Box>
      </AccordionDetails>
    </Accordion >
  );
}

function DataSourceEditor(props: DatabaseAdministrationPluginTargetProps &
{
  sensorCollection: SensorCollectionInformation;
  onChange?: (newSensorCollection: SensorCollectionInformation) => void | Promise<void>;
  onRequestEditorExit?: () => void | Promise<void>;
}) {
  const [dataSourceName, setDataSourceName] = React.useState<string | null>(props.sensorCollection.data_source_name);
  const [description, setDescription] = React.useState<string | null>(props.sensorCollection.description);
  const [displayName, setDisplayName] = React.useState<string | null>(props.sensorCollection.display_name);
  const [instanceDatabaseName, setInstanceDatabaseName] = React.useState<string | null>(props.sensorCollection.instance_database_name);
  const [readingsDatabaseName, setReadingsDatabaseName] = React.useState<string | null>(props.sensorCollection.readings_database_name);
  const [uniqueIdentifierField, setUniqueIdentifierField] = React.useState<string | null>(props.sensorCollection.unique_identifier_field);
  const [timestampFieldName, setTimestampFieldName] = React.useState<string | null>(props.sensorCollection.timestamp_field_name);
  const [timestampFieldFormat, setTimestampFieldFormat] = React.useState<string | null>(props.sensorCollection.timestamp_field_format);
  const [fields, setFields] = React.useState<SensorCollectionFieldInformation[]>(props.sensorCollection.fields);
  const [tags, setTags] = usePromise<(Tag & { applied: boolean; })[]>(async () => {
    const allTags = await serverConnection.getAllTags();
    return allTags.map(tag => ({ ...tag, applied: props.sensorCollection.tags.map(x => x.id).includes(tag.id) }));
  });
  const [readingRate, setReadingRate] = React.useState<number | null>(props.sensorCollection.reading_rate);

  const [applyPromise, setApplyPromise, resetApplyPromise] = usePromise<void>();

  const hasChanged = (field: keyof typeof props.sensorCollection) => {
    const values = {
      data_source_name: dataSourceName,
      description,
      display_name: displayName,
      instance_database_name: instanceDatabaseName,
      readings_database_name: readingsDatabaseName,
      unique_identifier_field: uniqueIdentifierField,
      timestamp_field_name: timestampFieldName,
      timestamp_field_format: timestampFieldFormat,
      fields,
      reading_rate: readingRate
    };

    if (props.sensorCollection[field] === '' || props.sensorCollection[field] === null || props.sensorCollection[field] === 0) {
      return false;
    }

    return values[field] !== props.sensorCollection[field];
  };

  useEffect(() => {
    setDataSourceName(props.sensorCollection.data_source_name);
    setDescription(props.sensorCollection.description);
    setDisplayName(props.sensorCollection.display_name);
    setInstanceDatabaseName(props.sensorCollection.instance_database_name);
    setReadingsDatabaseName(props.sensorCollection.readings_database_name);
    setUniqueIdentifierField(props.sensorCollection.unique_identifier_field);
    setTimestampFieldName(props.sensorCollection.timestamp_field_name);
    setTimestampFieldFormat(props.sensorCollection.timestamp_field_format);
    setFields(props.sensorCollection.fields);
    setReadingRate(props.sensorCollection.reading_rate);
  }, [props.sensorCollection]);

  const knownTimestampFieldFormats = [
    "ISO8601",
    "UNIX"
  ];

  const textFieldSX = {
    width: '100%',
    padding: '0.5em'
  };

  const applyChanges = React.useCallback(() => {
    if (tags.state !== 'fulfilled') {
      enqueueSnackbar('Tags are not loaded yet', { variant: 'error' });
      return;
    }
    if (props.onChange) {
      return Promise.resolve(props.onChange({
        data_source_name: dataSourceName!,
        description: description!,
        display_name: displayName!,
        instance_database_name: instanceDatabaseName!,
        readings_database_name: readingsDatabaseName!,
        unique_identifier_field: uniqueIdentifierField!,
        timestamp_field_name: timestampFieldName!,
        timestamp_field_format: timestampFieldFormat!,
        fields: fields!,
        tags: tags!.value.filter(x => x.applied),
        reading_rate: readingRate!
      }));
    }

    return Promise.resolve();
  }, [dataSourceName, description, displayName, instanceDatabaseName, readingsDatabaseName, uniqueIdentifierField, timestampFieldName, timestampFieldFormat, fields, readingRate]);

  const autoGenerateDBNames = () => {
    setInstanceDatabaseName(dataSourceName + '_instances');
    setReadingsDatabaseName(dataSourceName);
  };

  return (
    <>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography>Data Source Name</Typography>
            </TableCell>
            <TableCell>
              <TextField sx={textFieldSX} value={dataSourceName ?? ''} onChange={(e) => setDataSourceName(e.target.value)} variant='standard' />
              <Button onClick={autoGenerateDBNames}>
                Apply Source Name to Database Names
              </Button>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography>Description</Typography>
            </TableCell>
            <TableCell>
              <TextField sx={textFieldSX} value={description ?? ''} onChange={(e) => setDescription(e.target.value)} variant='standard' />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography>Display Name</Typography>
            </TableCell>
            <TableCell>
              <TextField sx={textFieldSX} value={displayName ?? ''} onChange={(e) => setDisplayName(e.target.value)} variant='standard' />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography>Instance Database Name</Typography>
            </TableCell>
            <TableCell>
              <TextField sx={textFieldSX} value={instanceDatabaseName ?? ''} onChange={(e) => setInstanceDatabaseName(e.target.value)} variant='standard' />
              <Collapse in={hasChanged('instance_database_name')}>
                <Alert severity='warning'>
                  Changing the instance database name could make existing sensor instances inacessible. Please be careful.
                </Alert>
              </Collapse>
              <Collapse in={!instanceDatabaseName.endsWith('_instances')}>
                <Alert severity='warning'>
                  The instance database name should end with <code>_instances</code>. This is not a requirement, but it is recommended.
                </Alert>
              </Collapse>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography>Readings Database Name</Typography>
            </TableCell>
            <TableCell>
              <TextField sx={textFieldSX} value={readingsDatabaseName ?? ''} onChange={(e) => setReadingsDatabaseName(e.target.value)} variant='standard' />
              <Collapse in={hasChanged('readings_database_name')}>
                <Alert severity='warning'>
                  Changing the readings database name could make existing readings inacessible. Please be careful.
                </Alert>
              </Collapse>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography>Unique Identifier Field</Typography>
            </TableCell>
            <TableCell>
              <TextField sx={textFieldSX} value={uniqueIdentifierField ?? ''} onChange={(e) => setUniqueIdentifierField(e.target.value)} variant='standard' />
              <Collapse in={hasChanged('unique_identifier_field')}>
                <>
                  <Alert severity='warning'>
                    Changing the unique identifier field could prevent sensor data from being correctly associated with a given sensor instance. Please be careful.
                  </Alert>
                  <Alert severity='info'>
                    You will need to flush the NGINX cache for this change to take effect on sensor data endpoints.
                  </Alert>
                </>
              </Collapse>
              <Collapse in={uniqueIdentifierField.length === 0}>
                <Alert severity='error'>
                  The unique identifier field cannot be empty.
                </Alert>
              </Collapse>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography>Timestamp Field Name</Typography>
            </TableCell>
            <TableCell>
              <TextField sx={textFieldSX} value={timestampFieldName ?? ''} onChange={(e) => setTimestampFieldName(e.target.value)} variant='standard' />
              <Collapse in={hasChanged('timestamp_field_name')}>
                <Alert severity='warning'>
                  Changing the timestamp field name could make it impossible to correctly associate timestamps with sensor data.
                </Alert>
              </Collapse>
              <Collapse in={timestampFieldName.length === 0}>
                <Alert severity='error'>
                  The timestamp field name cannot be empty.
                </Alert>
              </Collapse>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography>Timestamp Field Format</Typography>
            </TableCell>
            <TableCell>
              <TextField sx={textFieldSX} value={timestampFieldFormat ?? ''} onChange={(e) => setTimestampFieldFormat(e.target.value)} variant='standard' />

              {
                knownTimestampFieldFormats.map(format => (<Chip key={format} color={
                  format === props.sensorCollection.timestamp_field_format ? 'success' : 'warning'
                } label={format} onClick={() => {
                  setTimestampFieldFormat(format);
                }} sx={{
                  margin: '0.25em'
                }} />))
              }

              <Collapse in={hasChanged('timestamp_field_format')}>
                <Alert severity='warning'>
                  Changing the timestamp field format could make it impossible to correctly associate timestamps with sensor data.
                </Alert>
              </Collapse>
              <Collapse in={!knownTimestampFieldFormats.includes(timestampFieldFormat ?? '')}>
                <>
                  <Alert severity='error'>
                    The timestamp field format is not a known format. Only change this if you're really certain that you've got a valid format that the backend can parse.
                  </Alert>
                </>
              </Collapse>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography>Reading Rate</Typography>
            </TableCell>
            <TableCell>
              <TextField sx={textFieldSX} value={readingRate ?? ''} onChange={(e) => setReadingRate(parseInt(e.target.value))} variant='standard' />
              <Collapse in={hasChanged('reading_rate')}>
                <Alert severity='warning'>
                  Changing this value could cause Grafana visualizations to load incorrect time ranges.
                </Alert>
              </Collapse>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      {
        tags.state === 'fulfilled' && <GroupBox title='Tags' disableTitleAnimation>
          {
            tags.value.map(x => (
              <Tooltip key={x.id} title={x.description}>
                <Chip sx={{
                  margin: '0.25em'
                }} key={x.id} label={x.display_name} icon={
                  x.applied ? <CheckIcon /> : <CloseIcon />
                }
                  variant={
                    x.applied ? 'filled' : 'outlined'
                  }
                  color={x.applied ? 'primary' : undefined} onClick={() => {
                    let newTags = [...tags.value];
                    newTags.find(predicate => predicate.id === x.id)!.applied = !x.applied;
                    setTags(Promise.resolve(newTags));
                  }} />
              </Tooltip>
            ))
          }
        </GroupBox>
      }
      <>
        <GroupBox title='Fields' disableTitleAnimation>
          <>
            <TransitionGroup>
              {
                fields.map((field, index) => (<Collapse in={true} key={field.field_name}>
                  <DataSourceFieldEditor field={field} onChange={newField => {
                    const newFields = [...fields];
                    const index = newFields.findIndex(x => x.field_name === field.field_name);
                    newFields[index] = newField;
                    setFields(newFields);
                  }} requestDelete={() => {
                    const newFields = [...fields];
                    const index = newFields.findIndex(x => x.field_name === field.field_name);
                    newFields.splice(index, 1);
                    setFields(newFields);
                  }} />
                </Collapse>))
              }
            </TransitionGroup>
            <Box sx={{
              // Horizontally center
              display: 'flex',
              justifyContent: 'center',
              marginTop: '1em',
            }}>
              <Button startIcon={<AddIcon />} onClick={() => {
                const newFields = [...fields];
                newFields.push({
                  field_name: 'new_field',
                  display_name: 'New Field',
                  datatype: 'string',
                  kind: 'reported',
                  tags: [],
                  units_metric: 'N/A',
                  units_imperial: 'N/A'
                });
                setFields(newFields);
              }}>
                Add Field
              </Button>
            </Box>
          </>
        </GroupBox>

      </>
      <Box sx={{
        // Horizontally center
        display: 'flex',
        justifyContent: 'center',
        marginTop: '1em',
        // Vertically center
        alignItems: 'center',
        // Item spacing
        gap: '1em',
        // Force items on separate lines
        flexWrap: 'wrap'
      }}>

        {
          props.onRequestEditorExit && <Tooltip title={'Close Editor'}>
            <IconButton onClick={props.onRequestEditorExit}>
              <ArrowBackIcon />
            </IconButton>
          </Tooltip>
        }

        <IconButton sx={{
          color: applyPromise.state === 'fulfilled' ? 'success.main' : applyPromise.state === 'rejected' ? 'error.main' : undefined,
          borderColor: applyPromise.state === 'fulfilled' ? 'success.main' : applyPromise.state === 'rejected' ? 'error.main' : undefined,
          borderWidth: '2px',
          borderStyle: 'solid'
        }} disabled={
          applyPromise.state === 'pending'
        } onClick={async () => {
          const promise = applyChanges();
          setApplyPromise(promise);
          promise.finally(() => {
            delay(10000).then(() => resetApplyPromise());
          });
        }}>
          <SaveIcon />
        </IconButton>

      </Box>
      <Box sx={{
        // Horizontally center
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '1em',
        // Vertically center
        alignItems: 'center',
        // Item spacing
        gap: '1em',
        // Force items on separate lines
        flexWrap: 'wrap',
      }}>

        <TransitionGroup>
          {
            applyPromise.state === 'pending' && <Collapse in={true} key='promise-pending-dialog'>
              <Alert severity='info'>
                <AlertTitle>Saving</AlertTitle>
                Please wait while the changes are applied
              </Alert>
            </Collapse>
          }
          {
            applyPromise.state === 'fulfilled' && <Collapse in={true} key='promise-fulfilled-dialog'>
              <Alert severity='success'>
                <AlertTitle>Success</AlertTitle>
                Changes applied successfully
              </Alert>
            </Collapse>
          }
          {
            applyPromise.state === 'rejected' && <Collapse in={true} key='promise-rejected-dialog'>
              <Alert severity='error'>
                <AlertTitle>Error</AlertTitle>
                {
                  applyPromise.error.message ?? 'Unknown error'
                }
              </Alert>
            </Collapse>
          }
        </TransitionGroup>
      </Box>
    </>
  );
}

function DataSourceEditorHost(props: DatabaseAdministrationPluginTargetProps) {
  const [sensorCollections, setSensorCollections] = usePromise<SensorCollectionInformation[]>(() => {
    return serverConnection.getSensorCollectionsV2();
  });

  const [selectedSensorCollection, setSelectedSensorCollection] = useState<SensorCollectionInformation | null>(null);

  if (sensorCollections.state !== 'fulfilled') {
    return <CircularProgress />;
  }

  const addNewDataSource = () => {
    setSelectedSensorCollection({
      data_source_name: 'new_data_source',
      description: '',
      display_name: 'New Data Source',
      instance_database_name: '',
      readings_database_name: '',
      unique_identifier_field: '',
      timestamp_field_name: '',
      timestamp_field_format: '',
      fields: [],
      tags: [],
      reading_rate: 0
    });
  };

  return (
    <>
      <Typography variant='h6'>Data Source Editor</Typography>
      {
        sensorCollections.state === 'fulfilled' && selectedSensorCollection === null && <>
          <Masonry columns={5} spacing={1}>
            {
              sensorCollections.value.map(collection => (
                <Chip key={collection.data_source_name} label={collection.display_name} onClick={async () => {
                  setSelectedSensorCollection(collection);
                }} />
              ))
            }
          </Masonry>
          <Box sx={{
            // Horizontally center
            display: 'flex',
            justifyContent: 'center',
            marginTop: '1em',
          }}>
            <Button startIcon={<AddIcon />} onClick={
              addNewDataSource
            }>
              New Data Source
            </Button>
          </Box>
        </>
      }
      {
        selectedSensorCollection !== null && <DataSourceEditor {...props} sensorCollection={selectedSensorCollection} onChange={
          async (newSensorCollection) => {
            const dbAdmin = await serverConnection.getDatabaseAdministrationController();
            await dbAdmin.upsertDataSource(newSensorCollection);
            setSensorCollections(Promise.resolve([...sensorCollections.value]));
          }
        } onRequestEditorExit={
          () => setSelectedSensorCollection(null)
        } />
      }
    </>
  );
}

registerAdministrationPlugin({
  pluginID: 'data-source-editor',
  displayName: 'Data Source Editor',
  description: 'Edit the data sources in the database',
  target: DataSourceEditorHost
});


type DatabaseAdministrationPlugin = {
  pluginID: string;
  displayName: string;
  description: string;
  icon?: JSX.Element;
  target: (props: DatabaseAdministrationPluginTargetProps) => JSX.Element;
};



function registerAdministrationPlugin(plugin: DatabaseAdministrationPlugin) {
  administrationPlugins.push(plugin);
}

export type DatabaseAdministrationProps = {};

export default function DatabaseAdministrationPage(props: DatabaseAdministrationProps) {
  const [targetPluginID, setTargetPluginID] = useURLMappedBase64Value<string>('targetPluginID', (() => {
    if (administrationPlugins.length > 0) {
      return administrationPlugins[0].pluginID;
    }
    return null;
  })());
  const [isNGINXFlushDialogOpen, setIsNGINXFlushDialogOpen] = React.useState(false);

  const TargetComponent = React.useMemo(() => { return administrationPlugins.find(plugin => plugin.pluginID === targetPluginID)?.target ?? null; }, [targetPluginID]);

  return (
    <RequireUserScope scopes={['databaseAdministration']} mode='any' loadingComponent={
      <PageRoot headerProps={{
        title: 'Database Administration'
      }}>
        <PageBody>
          <Box sx={{
            // Center align vertically and horizontally
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%'
          }}>
            <CircularProgress />
          </Box>
        </PageBody>
      </PageRoot>
    } unavailableComponent={
      <>
        <PageRoot headerProps={{
          title: 'Database Administration'
        }}>
          <PageBody>
            <Box sx={{
              // Center align vertically and horizontally
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%'
            }}>
              <Alert severity='error'>
                <AlertTitle>
                  Restricted Access
                </AlertTitle>
                You do not have permission to access this page.
              </Alert>
            </Box>
          </PageBody>
        </PageRoot>
      </>
    }>
      <>
        <Dialog open={isNGINXFlushDialogOpen}>
          <DialogTitle>
            Flush NGINX Cache?
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to flush the NGINX cache? This will cause a short period of downtime.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsNGINXFlushDialogOpen(false)}>Cancel</Button>
            <Button onClick={async () => {
              setIsNGINXFlushDialogOpen(false);
              const dbAdmin = await serverConnection.getDatabaseAdministrationController();
              await dbAdmin.flushCache();
            }}>Flush</Button>
          </DialogActions>
        </Dialog>
        <PageRoot headerProps={{
          title: 'Database Administration'
        }}>
          <LeftMenu>
            <Typography variant='h6'>Database Administration</Typography>
            <List>
              {
                administrationPlugins.map(plugin => (
                  <ListItem key={plugin.pluginID} onClick={
                    () => setTargetPluginID(plugin.pluginID)
                  }>
                    <ListItemIcon>
                      {
                        plugin.icon ?? <EditNoteIcon />
                      }
                    </ListItemIcon>
                    <ListItemText primary={plugin.displayName} secondary={plugin.description} />
                  </ListItem>
                ))
              }
            </List>
            <Button startIcon={<DeleteForeverIcon />} color="error" onClick={async () => {
              setIsNGINXFlushDialogOpen(true);
            }} sx={{
              width: '100%'
            }}>Flush NGINX Cache</Button>
          </LeftMenu>
          <PageBody scrollable>
            {
              TargetComponent && <TargetComponent />
            }
          </PageBody>
        </PageRoot>
      </>
    </RequireUserScope>
  );
};
