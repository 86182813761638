import { Backdrop, CircularProgress, List, ListItem, ListItemSecondaryAction, ListItemText } from '@mui/material';
import * as React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { useEffect } from 'react';

const operations: Array<IBlockingOperation> = [];

var updateState = async () => { };


/**
 * Represents the status of a blocking operation. Allows to update the status of the operation.
 */
export class IBlockingOperation
{
    private id: string;
    private message: string;
    private progress: number;
    private isComplete: boolean;
    private isFailed: boolean;
    private startTime: Date;

    constructor(id: string, message: string, progress: number, isComplete: boolean, isFailed: boolean, startTime: Date)
    {
        this.id = id;
        this.message = message;
        this.progress = progress;
        this.isComplete = isComplete;
        this.isFailed = isFailed;
        this.startTime = startTime;
    }

    /**
     * Id of the operation. This is used to identify the operation and update its status.
     */
    public get Id(): string
    {
        return this.id;
    }

    /**
     * The message to display for the operation
     * */
    public get Message(): string
    {
        return this.message;
    }

    /**
     * The progress of the operation. -1 for indeterminate
     */
    public get Progress(): number
    {
        return this.progress;
    }

    /**
     * Indicates whether the operation is complete
     */
    public get IsComplete(): boolean
    {
        return this.isComplete;
    }

    /**
     * Indicates whether the operation has failed 
     */
    public get IsFailed(): boolean
    {
        return this.isFailed;
    }

    /**
     * The time when the operation was started
     */
    public get StartTime(): Date
    {
        return this.startTime;
    }

    public set Id(value: string)
    {
        this.id = value;
    }

    public set Message(value: string)
    {
        this.message = value;
        updateState();
    }

    public set Progress(value: number)
    {
        this.progress = value;
        updateState();
    }

    public set IsComplete(value: boolean)
    {
        this.isComplete = value;
        updateState();
    }

    public set IsFailed(value: boolean)
    {
        this.isFailed = value;
        updateState();
    }

    public set StartTime(value: Date)
    {
        this.startTime = value;
        updateState();
    }
}

/** 
* Registers a new blocking operation. This will activate the blocking operation dialog. 
* @param message Message to display
* @param progress Progress of the operation. -1 for indeterminate
* @param id Id of the operation. If not provided, a random id will be generated
* @returns A reference to the blocking operation status object which can be used to update the status of the operation
*/
export function AddBlockingOperation(message: string, progress: number, id: string | undefined = undefined): IBlockingOperation
{
    if (id === undefined)
    {
        id = Math.random().toString(36).substring(7);
    }
    const operation = new IBlockingOperation(id, message, progress, false, false, new Date());

    operations.push(operation);

    updateState();
    return operation;
}

export interface BlockingOperationControllerProps
{
    children: React.ReactNode[] | React.ReactNode;
    /**
     * The delay in milliseconds before the blocking operation dialog is closed after all operations have completed
     */
    dialogCloseDelay?: number;
}


export default function BlockingOperationController(props: BlockingOperationControllerProps)
{
    const [ runningOperationCount, setRunningOperationCount ] = React.useState(0);

    const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

    const [ isOpen, setIsOpen ] = React.useState(false);

    updateState = async () =>
    {
        let runningCount = 0;
        for (let i = 0; i < operations.length; i++)
        {
            if (!operations[ i ].IsComplete && !operations[ i ].IsComplete)
            {
                runningCount++;
            }
        }
        if (runningCount === 0)
        {
            // Wait for the dialog close delay before closing the dialog.
            // This allows the user to see the completed operation before the dialog closes.
            await delay(props.dialogCloseDelay ?? 1000);
        }
        setRunningOperationCount(runningCount);
    };

    useEffect(() =>
    {
        // Update the open state of the dialog based on the number of running operations
        setIsOpen(runningOperationCount > 0);
    }, [ runningOperationCount ]);

    useEffect(() =>
    {
        if (!isOpen)
        {
            // Remove completed and failed operations
            for (let i = 0; i < operations.length; i++)
            {
                if (operations[ i ].IsComplete || operations[ i ].IsFailed)
                {
                    operations.splice(i, 1);
                    i--;
                }
            }
        }
    }, [ isOpen ]);



    return (
        <>
            <Backdrop open={ isOpen } sx={ { color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 } }>
                <List sx={ { width: '100%', maxWidth: 360, bgcolor: 'background.paper' } }>
                    { operations.map((operation) => (
                        <ListItem key={ operation.Id }>
                            <ListItemText primary={ operation.Message } />
                            <ListItemSecondaryAction>
                                {
                                    (() =>
                                    {
                                        if (operation.IsComplete)
                                        {
                                            return <CheckCircleIcon color="success" fontSize='large' />;
                                        }
                                        else if (operation.IsFailed)
                                        {
                                            return <ErrorIcon color="error" fontSize='large' />;
                                        }
                                        else
                                        {
                                            return <CircularProgress color={
                                                new Date().getTime() - operation.StartTime.getTime() > 5000 ? "error" : "primary"
                                            } disableShrink={ true } variant={ operation.Progress !== -1 ? "determinate" : "indeterminate" } value={ operation.Progress } />;
                                        }
                                    })()
                                }
                            </ListItemSecondaryAction>
                        </ListItem>
                    )) }
                </List>
            </Backdrop>
            {
                props.children
            }
        </>
    );
}