import { AppBar, Box, Button, Collapse, Drawer, Fab, Grow, IconButton, LinearProgress, Slide, Toolbar, Typography, useScrollTrigger } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import React from "react";
import NavigationSidebarContent from "./NavigationSidebarContent";
import { useScroll } from "@react-three/drei";

const ROOT_APP_NAME = "Laurel Grove Wine Farm";

export interface HeaderProps
{
    /**
     * The title to display in the header.
     */
    title: string;
    /**
     * The actions to display in the header.
     */
    actions?: JSX.Element[];
    /**
     * The content to display in the drawer.
     */
    drawerContent?: JSX.Element;
    /**
     * The progress of the loading bar.
     */
    loadingProgress?: number;
}

/**
 * General header component for the application.
 * @param props The properties for the component.
 * @returns The rendered component.
 */
export default function Header(props: HeaderProps)
{
    const [ drawerOpen, setDrawerOpen ] = React.useState(false);
    const [ anchor ] = React.useState<'left' | 'right' | 'top' | 'bottom'>('left');
    const [ isCollapsed, setIsCollapsed ] = React.useState(false);
    const scrollTrigger = useScrollTrigger();
    React.useEffect(() =>
    {
        document.title = `${props.title} | ${ROOT_APP_NAME}`;
    }, [ props.title ]);

    React.useEffect(() =>
    {
        if (scrollTrigger)
        {
            setIsCollapsed(true);
        }
        else
        {
            setIsCollapsed(false);
        }
    }, [ scrollTrigger ]);

    return (
        <>
            <Box sx={ { flexGrow: 1 } }>
                <AppBar position="static">
                    <Toolbar>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={ { mr: 2 } }
                            onClick={ () => setDrawerOpen(true) }
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" component="div" sx={ { flexGrow: 1 } }>
                            { props.title }
                        </Typography>
                        {
                            props.actions?.map((action, index) =>
                            {
                                return <div key={ index }>{ action }</div>;
                            })
                        }
                    </Toolbar>
                </AppBar>
                <Slide in={ isCollapsed }>
                    <Fab color="primary" aria-label="add" sx={ {
                        position: 'absolute',
                        top: 0,
                        left: 0
                    } } onClick={ () => setIsCollapsed(false) }>
                        <MenuIcon />
                    </Fab>
                </Slide>
            </Box>


            <Drawer
                anchor={ anchor }
                open={ drawerOpen }
                onClose={ () => setDrawerOpen(false) }
            >
                <Box
                    sx={ { width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 } }
                    role="presentation"
                >
                    { props.drawerContent || <NavigationSidebarContent /> }
                </Box>
            </Drawer>
        </>
    );
}