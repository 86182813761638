import { ExhaustiveCodePathTypeChecking } from "../../shared/ExhaustiveCodePath";

// const baseFetch = globalThis.fetch;

export const globalCache: Promise<Cache | null> = (async () =>
{
    if (globalThis.isSecureContext)
    {
        return await globalThis.caches.open('globalCache');
    }
    else
    {
        return null;
    }
})();


// export type URLRewriter = (url: string) => string | undefined;

// const urlRewriters: URLRewriter[] = [];


// async function fetch(input: RequestInfo | URL, init?: RequestInit): Promise<Response>
// {
//     let url: string;
//     if (typeof input === "string")
//     {
//         url = input;
//     }
//     else if (input instanceof URL)
//     {
//         url = input.href;
//     }
//     else if (input instanceof Request)
//     {
//         url = input.url;
//     }
//     else
//     {
//         ExhaustiveCodePathTypeChecking(input);
//     }

//     for (const rewriter of urlRewriters)
//     {
//         const newURL = rewriter(url);
//         if (newURL)
//         {
//             url = newURL;
//         }
//     }

//     const cache: Cache | null = await globalCache;

//     if (cache)
//     {
//         const cachedResponse = await cache.match(url);
//         if (cachedResponse)
//         {
//             console.log(`[FetchHook] Cache hit for ${url}`);
//             return cachedResponse;
//         }
//     }

//     return await baseFetch(url, {
//         ...init,
//         headers: {
//             ...init?.headers,
//         },
//     });
// }

// globalThis.fetch = fetch;

// console.log(`Fetch hook installed.`);